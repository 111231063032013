.field {
    @extend .form-group;

    &.required:not(.choice), &._required:not(.choice) {
        > label {
            &:after {
                content: '*';
                color: $danger;
            }
        }
    }
    &._error {
        .input-text {
            border-color: $danger;
        }
    }
    div.mage-error, div.field-error {
        position: absolute;
        top: 2.5rem;
        color: $danger;
    }
    .customer-dob {
        > input {
            @extend .form-control;
        }
    }
}
.input-text, input[type=email] {
    @extend .form-control;

    &.mage-error {
        border-color: $danger;
    }
}
.choice {
    @extend .form-check;

    .checkbox, .radio, input {
        @extend .form-check-input;
    }
    .label {
        @extend .form-check-label;
    }
}
select {
    @extend .form-control;
}
textarea {
    @extend .form-control;
}
.alert-error {
    @extend .alert-danger;
}

.field-tooltip {
    .tooltip {
        display: none;
    }
    &.show, &._active {
        .tooltip {
            display: block;
            opacity: 0.9;
            bottom: 100%;
            right: 0;

            .arrow {
                right: 0.2rem;
            }
        }
    }
}
