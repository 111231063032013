// Global theme variables
$margin-x:                              ($spacer * 2) !default; // Left & Right
$margin-y:                              ($spacer * 2) !default; // Top & Bottom
$padding-x:                             ($spacer * 2) !default; // Left & Right
$padding-y:                             ($spacer * 2) !default; // Top & Bottom

$main-breakpoint:                       md !default;
$font-weight-icon:                      400 !default;


// Breadcrumbs
$breadcrumb-height:                     3rem !default;


// Swatches
$swatch-padding-y:                      .25rem !default;
$swatch-padding-x:                      .4rem !default;
$swatch-width:                          ($spacer * 2.5) !default;
$swatch-height:                         ($spacer * 2) !default;
$swatch-image-width:                    8.125rem !default;
$swatch-image-height:                   8.125rem !default;
$swatch-font-size:                      $font-size-base !default;


// Slick gallery
$photo-hover-cursor:                    zoom-in !default;
$photo-loader-background:               $white !default;
$photo-slider-arrow-bg:                 transparent !default;
$photo-slider-arrow-color:              $black !default;
$photo-slider-arrow-size:               2rem !default;

$photo-thumbs-arrow-bg:                 transparent !default;
$photo-thumbs-arrow-color:              $black !default;
$photo-thumbs-arrow-size:               1.5rem !default;

$slick-dots-background:                 $gray-200 !default;
$slick-dots-background-active:          $primary !default;
$slick-dots-width:                      12px !default;
$slick-dots-height:                     12px !default;
$slick-dots-border-radius:              50% !default;

$player-button-width:                   4rem !default;
$player-button-height:                  4rem !default;
$player-button-background:              $primary !default;
$player-button-border-radius:           50% !default;
$player-button-color:                   $white !default;

$photo-lightbox-max-width:              1200px !default;
$photo-lightbox-max-height:             85vh !default;
$photo-lightbox-background:             rgba(0, 0, 0, 0.3) !default;
$photo-lightbox-arrow-bg:               transparent !default;
$photo-lightbox-arrow-color:            $white !default;
$photo-lightbox-arrow-size:             2rem !default;
$photo-lightbox-close-bg:               transparent !default;
$photo-lightbox-close-color:            $white !default;
$photo-lightbox-close-size:             2.5rem !default;


// Product
$spinbox-width:                         ($spacer * 4)  !default;


// Offcanvas
$zindex-offcanvas:                      1070 !default;
$zindex-offcanvas-backdrop:             1060 !default;

$offcanvas-heading-bg:                  $primary !default;
$offcanvas-heading-color:               $white !default;
$offcanvas-bg:                          $white !default;
$offcanvas-backdrop-bg:                 $black !default;
$offcanvas-backdrop-opacity:            .5 !default;

$offcanvas-max-width:                   25rem !default;
$offcanvas-font-size:                   $h5-font-size !default;

$offcanvas-fade-transform:              translateX(-100%) !default;
$offcanvas-show-transform:              none !default;
$offcanvas-transition:                  transform .2s ease-out !default;


// Buttons
$btn-primary:                           $primary !default;
$btn-secondary:                         $secondary !default;
$btn-search:                            $primary !default;
$btn-addtocart:                         $teal !default;
$btn-cta:                               $teal !default;
$btn-wishlist:                          $light !default;
$btn-compare:                           $light !default;
$btn-login:                             $primary !default;
$btn-spinbox:                           $input-border-color !default;
$btn-back:                              $gray-600 !default;

// Review
$review-icon-color:                     $yellow !default;

//cart counter
$cart-counter-background: $primary !default;
$cart-counter-foreground: $white !default;

$cart-counter-mobile-width: 1.2rem !default;
$cart-counter-mobile-height: 1.2rem !default;
$cart-counter-mobile-left: initial !default;
$cart-counter-mobile-top: initial !default;
$cart-counter-mobile-bottom: -0.2rem !default;
$cart-counter-mobile-right: -0.8rem !default;
$cart-counter-mobile-fontsize: 0.6rem !default;

$cart-counter-desktop-width: 1.2rem !default;
$cart-counter-desktop-height: 1.2rem !default;
$cart-counter-desktop-left: initial !default;
$cart-counter-desktop-top: initial !default;
$cart-counter-desktop-bottom: 0.2rem !default;
$cart-counter-desktop-right: -0.5rem !default;
$cart-counter-desktop-fontsize: 0.6rem !default;
