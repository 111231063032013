.swatch-layered {
    .swatch-attribute-options {
        margin: map-get($spacers, 2) 0;
    }
}
.swatch-attribute {
    margin-bottom: map-get($spacers, 2);
}
.swatch-attribute-options {
    .swatch-option-link-layered {
        display: inline-block;
    }
}
.swatch-option {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 map-get($spacers, 2);
    margin: 0.25rem 0.4rem 0.25rem 0;
    min-width: $swatch-width;
    height: $swatch-height;
    border: 1px solid $gray-200;
    box-shadow: 0 0 0 1px $white;
    cursor: pointer;
    @include border-radius($border-radius);

    &:not(.disabled):hover {
        box-shadow: 0 0 0 1px $gray-500;
    }
    &:not(.disabled):focus {
        box-shadow: 0 0 0 1px $gray-700;
    }
    &:first-child {
        margin-left: 0;
    }
    &.color {
        width: $swatch-width;
        height: $swatch-height;
    }
    &.text {
        background: $gray-100;
        color: $gray-800;
        font-size: $swatch-font-size;
        @include border-radius($border-radius);
    }
    &.image {
        padding: 0;
    }
    &.selected {
        box-shadow: 0 0 0 1px $gray-700;
    }
    &.disabled {
        cursor: default;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: linear-gradient(
                to left top,
                rgba( 255, 255, 255, 0) 0%,
                rgba( 255, 255, 255, 0) 42%,
                rgba( 255, 255, 255, 0.5) 43%,
                rgba( 255, 255, 255, 0.8) 47%,
                rgba( 255, 82, 22, 1) 50%,
                rgba( 255, 82, 22, 1) 50%,
                rgba( 255, 255, 255, 0.8) 53%,
                rgba( 255, 255, 255, 0.5) 57%,
                rgba( 255, 255, 255, 0) 58%,
                rgba( 255, 255, 255, 0) 100%
            );
        }
    }
}
.swatch-attribute-label {
    font-weight: bold;
    position: relative;

    &.required {
        padding-right: 10px;
    }
    &[data-required="1"]:after {
        content: '*';
        color: $danger;
        position: absolute;
        right: -11px;
        top: -2px;
        font-weight: bold;
        font-size: 1em;
    }
}
.swatch-attribute-selected-option {
    color: #646464;
    padding-left: 17px;
}
.swatch-option-tooltip {
    display: none;
    position: absolute;
    max-width: $tooltip-max-width;
    max-height: 100%;
    min-height: $swatch-height;
    min-width: $swatch-width;
    padding: 0.25rem;
    background: $white;
    border: 1px solid $gray-400;
    text-align: center;
    @include border-radius($border-radius);
    z-index: 999;
}
.swatch-option-tooltip, .swatch-option-tooltip-layered {
    .corner {
        left: 40%;
        position: absolute;
        bottom: -2px;
        height: 8px;

        &:before, &:after {
            content: '';
            position: relative;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 7px 7.5px 0 7.5px;
            font-size: 1px;
        }
        &:before {
            top: 2px;
            left: 0;
            border-color: $gray-400 transparent transparent transparent;
        }
        &:after {
            top: 1px;
            left: -15px;
            border-color: $white transparent transparent transparent;
        }
    }
    .image {
        display: block;
        height: $swatch-image-width;
        width: $swatch-image-height;
        margin: 0 auto;
    }
    .title {
        display: block;
        max-width: 140px;
        min-height: 20px;
        max-height: 200px;
        color: $gray-700;
        text-align: center;
        overflow: hidden;
    }
}
.swatch-opt {
    margin: map-get($spacers, 3) 0;
}
.swatch-more {
    display: inline-block;
    margin: 2px 0;
    text-decoration: none !important;
    position: static;
    z-index: 1;
}
.swatch-input {
    left: -1000px;
    position: absolute;
    visibility: hidden;
}
