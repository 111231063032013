.btn-spinbox {
    @include button-outline-variant($btn-spinbox);
}
.btn-cta {
    @include button-variant($btn-cta, $btn-cta);
}
.actions-toolbar {
    > .primary, > .secondary {
        display: inline-block;
        
        .action {
            @extend .btn;
        }
    }
    > .primary {
        .primary {
            @include button-variant($btn-primary, $btn-primary);
        }
    }
}
