.navbar-wishlist, .header-wishlist {
    position: relative;

    .icon {
        @include svg-icon(1rem, 1rem, $icon-wishlist, $body-color);
    }
    .counter {
        &.empty {
            display: none;
        }
    }
}
.navbar-wishlist {
    .text {
        display: none;
    }
}
.wishlist-index-index {
    .products-grid.wishlist {
        .product-item {
            .product-item-info {
                position: relative;
            }
            .qty {
                display: none;
            }
            .product-item-tooltip {
                margin-bottom: 1rem;
                .action {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;

                    &:after {
                        @include svg-icon(1rem, 1rem, $icon-angle-down, $body-color);
                    }
                }
                &.active {
                    .action {
                        &:after {
                            @include svg-icon(1rem, 1rem, $icon-angle-up, $body-color);
                        }
                    }
                }
                .subtitle {
                    display: none;
                }
                dl {
                    .item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                }
            }
            .product-item-actions {
                .btn-remove {
                    position: absolute;
                    top: 0rem;
                    right: 0rem;
                    @extend .btn;
                    @include button-variant($danger, $danger);

                    &:before {
                        @include svg-icon(1rem, 1rem, $icon-trash, $white);
                    }
                    span {
                        display: none;
                    }
                }
            }
        }
    }
    .form-wishlist-items {
        .actions-toolbar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;

            .primary {
                order: 2;
                .tocart {
                    @include button-variant($btn-cta, $btn-cta);
                }
            }
            .secondary {
                order: 1;
            }
        }
    }
}
.block-wishlist {
    @include media-breakpoint-down(sm) {
        display: none;
    }
    .block-title {
        .counter {
            color: $text-muted;
            font-size: $font-size-sm;
        }
    }
    .block-content {
        .product-items {
            padding: 0;
            list-style-type: none;

            .product-item {
                .product-item-info {
                    @include make-row();
                    margin-right: -4px;
                    margin-left: -4px;

                    .product-item-photo, .product-item-details {
                        @include make-col-ready();
                        padding-left: 4px;
                        padding-right: 4px;
                    }
                    .product-item-photo {
                        @include make-col(4);
                    }
                    .product-item-details {
                        @include make-col(8);
                    }
                }
            }
        }
        .product-item-actions {
            .actions-primary, .actions-secondary {
                display: inline-block;
            }
            .actions-primary {
                .tocart {
                    &:before {
                        @include svg-icon(1rem, 1rem, $icon-cart-plus, $white);
                    }
                    span {
                        display: none;
                    }
                }
            }
            .actions-secondary {
                .btn-remove {
                    @extend .btn;
                    
                    &:before {
                        @include svg-icon(1rem, 1rem, $icon-trash, $body-color);
                    }
                    span {
                        display: none;
                    }
                }
            }
        }
    }
}
