.catalog-product-view {

    h1 {
        .base {
            color: $gray-900;
            font-weight: $headings-font-weight;
            @include font-size($h2-font-size);
        }
    }

    .product-usp {
        .block-title {
            @include font-size($h5-font-size);
        }
    }
}