.amblog-index-post,
.amblog-index-index {

    h1,
    h1.page-title {
        margin-bottom: map-get($spacers, 5);
        color: $primary;
        font-weight: $headings-font-weight;
        @include font-size($h1-font-size);
    }
    .sidebar {
        .amblog-element-block {
            background-color: lighten($gray-100, 2.75);

            .amblog-recentposts {
                padding: 0.875rem;

                .amblog-title {
                    padding: 0 0 10px 0;
                    @include font-size($h3-font-size);
                    text-transform: capitalize;
                    color: $primary;
                }

                .amblog-item {
                    padding: 0;
                    border: none;

                    .amblog-headline {
                        text-decoration-color: $gray-700;
                        .icon {
                            @include svg-icon(1rem, 1rem, $icon-angle-right, $gray-700);
                            vertical-align: middle;
                        }

                        span {
                            font-size: 14px;
                            font-weight: $font-weight-light;
                            color: $gray-700;
                            line-height: 1.75;
                        }
                    }
                }
            }
        }
    }
}

.amblog-index-post {
    .container-main {
        .blog-image {
            padding: 3.75rem 1.25rem 1.875rem;
            background-image: url("../images/brushed_bg_big.png");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 65%;

            .image-wrapper {
                display: inline-block;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-image: url("../images/polaroid-bg.png");
                    box-shadow: $box-shadow;
                    transform: rotate(6deg) scale(1.0) translateY(6px);
                    z-index: 1;
                }
            }

            .image,
            .post-thumbnail {
                position: relative;
                margin-bottom: 1.5em;
                padding: 1.429em;
                background-image: url("../images/polaroid-bg.png");
                box-shadow: $box-shadow;
                z-index: 5
            }
        }
        .amblog {
            &-element-block {
                border: none;
                box-shadow: none;

                .am-content {
                    p {
                        line-height: 1.5rem;
                    }

                    h2 {
                        color: $pink;
                        @include font-size($h2-font-size);
                    }
                }
            }
        }
    }
}

.amblog-list {
    .amblog-post {
        padding-bottom: 1.429em;
        margin-bottom: 2.858em;
        background: url('../images/brushed_bg_big.png') no-repeat center bottom;
        background-size: 93%;
        overflow: visible;

        .polariod {
            display: inline-block;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: url('../images/polaroid-bg.png');
                -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
                -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
                box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
                transform: rotate(6deg);
                z-index: 1;
            }

            .image {
                position: relative;
                margin-bottom: 1.5em;
                padding: 1.429em;
                background-image: url('../images/polaroid-bg.png');
                -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
                -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
                box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
                z-index: 5
            }

            img {
                display: inline-block;
                position: relative;
                z-index: 3;
                margin-bottom: 1em;
                -webkit-box-shadow: 0px 2px 59px 12px rgba(255, 255, 255, 1);
                -moz-box-shadow: 0px 2px 59px 12px rgba(255, 255, 255, 1);
                box-shadow: 0px 2px 59px 12px rgba(255, 255, 255, 1);
                border: 1px solid rgba(0, 0, 0, 0.15);
            }

            // @media (max-width: @grid-float-breakpoint-max) {
            //     margin-bottom: 0.75em;
            // }
        }

        h2 {
            color: $pink;
            margin-top: 0;
            display: block;
            position: relative;
            z-index: 999;
            text-align: center;

            &.post-title {
                @include font-size($h2-font-size);
            }

            a {
                color: $pink;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .std {
            padding: 0 2.143em;

            .am-read-more {
                background-color: $primary;
                border-color: $primary;

                &:hover, &:focus {
                    background-color: lighten($primary, 5);
                    border-color: lighten($primary, 5);
                    box-shadow: none;
                }
            }

            p {
                min-height: 300px;
                line-height: 1.500em;
            }
        }

        .post-bg {
            p {
                line-height: 1.500em;
            }
        }

        &:last-child {
            border: none;
        }

        .btn-cta {
            text-transform: none;
            font-size: 1em;
            font-weight: normal;
            text-transform: none;
        }

        .post-meta {
            margin-top: 1em;

            .date {
                i {
                    padding-right: 0.25em;
                }
            }

            .am-tags {
                display: none;
            }
        }
    }

    .mbplog-recent-posts {
        .amblog-recent-post {
            list-style: none;
            margin-left: 0;
            padding-left: 0;
            line-height: 1.75em;
        }
    }
}

.breadcrumbs-wrapper {
    .breadcrumb {
        padding: 10px 0 10px 0;
    }
}


body {
    &[class*="amblog"] {
        .icon {
            &.icon-at {
                @include svg-icon(1rem, 1rem, $icon-at, $body-color);
            }

            &.icon-chevron-left {
                @include svg-icon(1rem, 1rem, $icon-angle-left, $body-color);
            }
            &.icon-chevron-right {
                @include svg-icon(1rem, 1rem, $icon-angle-right, $body-color);
            }
        }
    }
}
