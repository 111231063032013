.amblog-index-post,
.amblog-index-index {

    h1,
    h1.page-title {
        color: $primary;
        padding: 20px 0 20px 0;
        font-family: $headings-font-family;
        @include font-size($h2-font-size);
    }
    .sidebar {
        .amblog-element-block {
            background-color: lighten($gray-100, 2.75);

            .amblog-recentposts {
                padding: 0.875rem;

                .amblog-title {
                    color: $primary;
                    text-transform: capitalize;
                    padding: 5px 0 10px 0;
                    @include font-size($h3-font-size);
                }

                .amblog-item {
                    padding: 0;
                    border: none;

                    .amblog-headline {
                        text-decoration-color: $gray-700;
                        .icon {
                            @include svg-icon(1rem, 1rem, $icon-angle-right, $gray-700);
                            vertical-align: middle;
                        }

                        span {
                            font-size: 14px;
                            font-weight: $font-weight-light;
                            color: $gray-700;
                            line-height: 1.75;
                        }
                    }
                }
            }
        }
    }
}

.amblog-list {
    .amblog-post {
        background: none;

        &:last-child {
            border: transparent;
            border-bottom: 0.25rem dashed $gray-100;
        }

        h2 {
            text-align: left;
            color: $primary;

            &.post-title {
                @include font-size($h5-font-size);
            }

            a {
                color: $primary;
            }
        }

        .std {
            padding: 0;
            font-size: $font-size-sm;

            p {
                color: $gray-500;
            }

            .am-read-more {
                display: flex;
                align-items: center;
                background-color: $white;
                font-size: $font-size-sm;

                &::after {
                    @include svg-icon(1rem, 1rem, $icon-angle-right, $link-color);
                }

                &:hover {
                    background-color: $white;
                    text-decoration: underline;
                    color: $link-hover-color;
                }
            }
        }
    }
}
