header {
    .header {
        padding-bottom: 2rem;
        .header-sub {
            .logo-relative {
                .logo {
                    display: block;
                    height: 3rem;
                    position: relative;
                    img {
                        position: absolute;
                        top: -2.5rem;
                    }
                }
            }
        }
    }

    &.page-header {
        .top-wrapper {
            .top-header {
                @include media-breakpoint-only(md) {
                    justify-content: flex-start;
                }
                .__fbcw__bar-widget {
                    @include media-breakpoint-down($main-breakpoint) {
                        display: none !important;
                    }
                }
                .__fbcw__bar-widget__score,
                .__fbcw__bar-widget__amount-of-reviews{
                        color: white !important;
                        font-family: $font-family-sans-serif !important;
                        font-size: $font-size-sm !important;
                    }
                .top-usp {
                    @include media-breakpoint-down($main-breakpoint) {
                        margin-left: 0;
                        padding-left: 0;
                    }

                    @include media-breakpoint-only(md) {
                        justify-content: flex-start;
                    }
                    .usp-block {
                        a {
                            font-family: $headings-font-family;
                            font-size: $font-size-sm;

                            .icon-truck {
                                @include svg-icon(1rem, 1rem, $icon-truck, $white);
                                @include media-breakpoint-down(sm) {
                                    margin-left: $spacer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.header-minicart,
.navbar-cart {
    .icon {
        &::before {
            @include svg-icon(2rem, 2rem, $icon-cart, $gray-300);
        }
    }

    .counter {
        background-color: $primary;
        left: 1.25rem;
        top: -0.6rem;

        @include media-breakpoint-down($main-breakpoint) {
            left: 0.35rem;
            top: -0.4rem;
        }
    }
}

.block-search {
    .form-group {
        .input-group {
           input {
               background-color: lighten($gray-100, 2.75);
               border-radius: 0 !important;
               color: lighten($black, 73);
               border-color: lighten($gray-100, 2.75);
           }
        }
    }
}

.breadcrumbs-wrapper, .breadcrumbs, .breadcrumb {
    background-color: $white;
}

.breadcrumb {
    .breadcrumb-item {
        &:not(.active) {
            a {
                color: $gray-300;
            }
        }
        &.active {
            a {
                color: $gray-500;
            }
        }
    }
}
