.navbar {
    .navbar-header {
        display: none;
        align-items: center;
        justify-content: space-between;

        @include media-breakpoint-down($main-breakpoint) {
            display: flex;
            width: 100%;

            .navbar-header-left,
            .navbar-header-right {
                width: 35%;

                .btn {
                    padding: 0 0.25rem;
                    
                    &:first-child {
                        padding-left: 0;
                    }

                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
    
            .navbar-header-center {
                width: 30%;
                text-align: center;
            }
    
            .navbar-header-left {
                text-align: left;
            }
    
            .navbar-header-right {
                text-align: right;
            }
        }
    }

    .navbar-brand {
        display: block;
        margin-right: 0;
    }

    .navbar-collapse {
        padding: 0;
    }

    .navbar-nav {
        .navbar-nav-header {
            display: none;
        }

        @include media-breakpoint-down($main-breakpoint) {
            padding: $spacer;

            .navbar-nav-header {
                margin-top: -$spacer;
                margin-left: -$spacer;
                margin-right: -$spacer;
                display: block;
                padding: $spacer;
                background: $gray-200;
                font-weight: $font-weight-bolder;
            }
        }
    }

    .category-item {
        >a {
            padding-top: $nav-link-padding-y;
            padding-bottom: $nav-link-padding-y;
            padding-right: $navbar-nav-link-padding-x;
            padding-left: $navbar-nav-link-padding-x;
        }
    }
}
