.cms-home {
    .column.main {
        .btn-default {
            padding: 0.3rem 0.75rem;
            border: 2px solid $secondary;
            color: $secondary;

            &::after {
                @include svg-icon(0.85rem, 0.85rem, $icon-arrow-right, $primary);
                margin-left: map-get($spacers, 1);
                vertical-align: middle;
            }

            &:hover {
                color: $white;
                background-color: $secondary;

                &::after {
                    @include svg-icon-hover(0.85rem, 0.85rem, $icon-arrow-right, $white);
                }
            }
            
            &:focus {
                box-shadow: none;
            }
        }
    }
}
