.checkout-index-index {
    padding-bottom: 2em;
    background: linear-gradient(to right, $white 50%, $gray-100 50%);
    
    @include media-breakpoint-down($main-breakpoint) {
        background: none;
    }
    .opc-sidebar {
        padding-left: map-get($spacers, 3);

        @include media-breakpoint-down(sm) {
            padding-left: 0;
        }
    }
    .opc-wrapper {
        padding-right: map-get($spacers, 3);

        @include media-breakpoint-down(sm) {
            padding-right: 0;
        }
    }
    .progress-authetication-wrapper {
        display: flex;
        height: 3rem;
        justify-content: space-between;
        align-items: center;

        .authentication-wrapper {
            .action-auth-toggle {
                @include button-outline-variant($btn-login, $btn-login);

                &:hover {
                    color: $white;
                }
            }
            .modal-header {
                .action-close {
                    display: none;
                }
            }
            .block-new-customer, .block-customer-login {
                padding-bottom: $spacer;
                
                .block-title {
                    margin-bottom: $headings-margin-bottom * 2;
                    font-family: $headings-font-family;
                    font-weight: $headings-font-weight;
                    line-height: $headings-line-height;
                    color: $headings-color;
                    @include font-size($h4-font-size);
                }
            }
            .actions-toolbar {
                .primary {
                    .action-login {
                        @include button-variant($btn-login, $btn-login);
                    }
                }
            }
        }
        .opc-progress-bar {
            display: flex;
            align-items: center;
            margin-bottom: 0;
            padding: 0;
            list-style-type: none;

            > li {
                display: inline-flex;
                color: $text-muted;
                align-items: center;

                > a {
                    color: $body-color;
                    text-decoration: none;
                }
                &._active, &._complete {
                    color: $body-color;
                    font-weight: $font-weight-bold;
                }
                &._active {
                    span {
                        border-bottom: 2px solid $teal;
                    }
                }
                &._complete {
                    cursor: pointer;
                }
                + li {
                    &:before {
                        content:' ';
                        margin: 0 0.5rem;
                        @include svg-icon(1rem, 0.75rem, $icon-angle-right);
                    }
                }
                @include media-breakpoint-down($main-breakpoint) {
                    &._active, &._complete {
                        span, a {
                            border-color: $success;
                            color: $success;
                        }
                        .opc-progress-bar-item-cart {
                            &:before {
                                @include svg-icon(1rem, 1rem, $icon-checkout-cart, $success);
                            }
                        }
                        .opc-progress-bar-item-shipping {
                            &:before {
                                @include svg-icon(1rem, 1rem, $icon-checkout-shipping, $success);
                            }
                        }
                        .opc-progress-bar-item-payment {
                            &:before {
                                @include svg-icon(1rem, 1rem, $icon-checkout-payment, $success);
                            }
                        }
                    }
                    > span, > a {
                        font-size: 0;

                        &.opc-progress-bar-item-cart {
                            &:before {
                                @include svg-icon(1rem, 1rem, $icon-checkout-cart, $gray-300);
                            }
                        }
                        &.opc-progress-bar-item-shipping {
                            &:before {
                                @include svg-icon(1rem, 1rem, $icon-checkout-shipping, $gray-300);
                            }
                        }
                        &.opc-progress-bar-item-payment {
                            &:before {
                                @include svg-icon(1rem, 1rem, $icon-checkout-payment, $gray-300);
                            }
                        }
                    }
                }
            }
        }
    }
    .opc-estimated-wrapper {
        display: none;
        align-items: center;
        justify-content: space-between;
        padding: $spacer;
        margin-top: $spacer;
        margin-left: -($grid-gutter-width / 2);
        margin-right: -($grid-gutter-width / 2);
        margin-bottom: $spacer;
        background: $gray-100;

        @include media-breakpoint-down(sm) {
            display: flex;
        }
        .estimated-block {
            font-weight: $font-weight-bold;

            .estimated-label, .estimated-price {
                display: block;
            }
        }
        .minicart-wrapper {
            .showcart {
                display: flex;
                align-items: center;

                &:before {
                    @include svg-icon(1rem, 1rem, $icon-checkout-cart, $body-color);
                    margin-right: 0.25rem;
                }
                @include button-outline-variant($btn-secondary, $btn-secondary);
            }
        }
    }
    .step-title {
        display: block;
        margin-bottom: $headings-margin-bottom * 2;
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        line-height: $headings-line-height;
        color: $headings-color;
        @include font-size($h3-font-size);
    }
    .opc {
        padding: 0;
        list-style-type: none;

        .checkout-shipping-address {
            .new-address-popup {
                margin-bottom: $spacer;

                .action-show-popup {
                    @include button-variant($btn-secondary, $btn-secondary);
                }
            }
            .shipping-address-items {
                @include make-row();
                margin: 0;
                
                .shipping-address-item {
                    @include make-col(6);

                    &.selected-item {
                        .address {
                            position: relative;
                            border: 1px solid $primary;
                            @include border-radius($border-radius);

                            &:before {
                                @include svg-icon(1rem, 1rem, $icon-check, $white);
                                position: absolute;
                                top: 0;
                                right: 0;
                                padding: ($spacer * 0.3) ($spacer * 0.5);
                                background: $primary;
                            }
                        }
                    }
                    .address {
                        padding: $spacer;
                    }
                    .action {
                        margin-top: map-get($spacers, 3);
                        @include button-outline-variant($btn-secondary, $btn-secondary);
                    }
                }
            }
        }
        .checkout-shipping-method {
            .table-checkout-shipping-method {
                thead {
                    display: none;
                }
            }
        }
        .checkout-payment-method {
            .discount-code {
                margin-bottom: $spacer;

                .payment-option-title {
                    @extend .step-title;
                    cursor: pointer;

                    &:after {
                        @include svg-icon(1rem, 1rem, $icon-angle-down);
                        float: right;
                    }
                }
                &._active {
                    .payment-option-title {
                        &:after {
                            @include svg-icon(1rem, 1rem, $icon-angle-up);
                        }
                    }
                }
                .action-apply, .action-cancel {
                    @include button-variant($btn-secondary, $btn-secondary);
                }
            }
            .payment-method {
                padding: $spacer;

                &._active {
                    background: $gray-100;
                    @include border-radius($border-radius);

                    .payment-method-title {
                        margin: 0;
                        margin-bottom: 1em;
                    }
                    .payment-method-content {
                        display: block;
                    }
                }
                .payment-method-title {
                    margin: 0;

                    .radio {
                        margin-top: 0.2rem;
                    }
                }
                .payment-method-content {
                    display: none;
                    padding-left: $spacer;
                    margin-top: $spacer / 2;

                    .billing-address-details {
                        padding-left: $spacer * 1.25;
                        padding-right: $spacer;

                        .action-edit-address {
                            margin-top: $spacer;
                            @include button-outline-variant($btn-secondary, $btn-secondary);
                        }
                    }
                    .checkout-billing-address {
                        margin-bottom: $spacer;

                        .actions-toolbar {
                            .primary {
                                display: block;
                            }
                            .action-update {
                                @include button-variant($btn-primary, $btn-primary);
                            }
                            .action-cancel {
                                float: left;
                                @include button-outline-variant($btn-secondary, $btn-secondary);
                            }
                        }
                    }
                    .billing-address-same-as-shipping-block {
                        margin-bottom: $spacer / 2;
                    }
                    .actions-toolbar {
                        margin-top: $spacer;
                    }
                }
            }
            .payment-method-billing-address {
                .billing-address-form {
                    padding: $spacer;
                    background: $white;
                    @include border-radius($border-radius);

                    .actions-toolbar {
                        .action-update {
                            float: right;
                            @include button-variant($btn-secondary, $btn-secondary);
                        }
                    }
                }
            }
        }
        .actions-toolbar {
            text-align: right;
        }
    }
    .actions-toolbar {
        > .primary {
            .primary {
                @include button-variant($btn-cta, $btn-cta);
            }
        }
    }
    .float-label {
        position: relative;

        label {
            position: absolute;
            top: 1.2rem;
            left: 1rem;
            padding-left: 3px;
            padding-right: 3px;
            color: $gray-600;
            transform: translateY(-50%);
            transition: all .3s ease;
            cursor: text;
            z-index: 10;
        }
        &.float-label-state {
            label {
                top: 0;
                background: #fff;
                color: $gray-500;
            }
        }
    }
    fieldset {
        &.street {
            margin-bottom: 0;
            
            legend {
                display: none;
            }
            > .control {
                @include make-row(($grid-gutter-width / 2));

                .field {
                    @include make-col-ready(($grid-gutter-width / 2));
                    @include make-col(6);

                    .label {
                        left: 1.4rem;
                    }
                }
                .field:only-child {
                    @include make-col(12);
                }
                .additional {
                    @include make-col(3);
                }
            }
        }
        &.shopcommerce_postcode_fieldset {
            > .control {
                @include make-row(($grid-gutter-width / 2));

                .field {
                    @include make-col-ready(($grid-gutter-width / 2));
                    @include make-col(4);

                    .label {
                        left: 1.4rem;
                    }
                }
            }
        }
    }
    .opc-sidebar {
        .close {
            display: none;
        }
        @include media-breakpoint-down(sm) {
            

            .close {
                display: block;
            }
            .opc-block-summary, .opc-block-shipping-information {
                padding: 1rem;
            }
        }
        &.extra {
            margin-top: map-get($spacers, 4);

            .opc-custom-wrapper {
                ul {
                    @extend .list-inline;

                    li { 
                        @extend .list-inline-item;
                    }
                }
                &.links {
                    @include font-size($font-size-sm);

                    ul {
                        li {
                            padding-right: $spacer * 0.6;
                            border-right: 1px solid $gray-300;

                            &:last-child {
                                border-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .opc-block-summary {
        > .title {
            display: none;
            @extend .step-title;
        }
        .items-in-cart {
            .title {
                margin-bottom: $headings-margin-bottom;
            }
        }
        .minicart-items {
            padding: 0;
            list-style-type: none;

            .product-item {
                > .product {
                    @include make-row(($grid-gutter-width / 2));

                    .product-image-container, .product-item-details {
                        @include make-col-ready(($grid-gutter-width / 2));
                    }
                    .product-image-container {
                        @include make-col(2);

                        .product-image-wrapper {
                            > img {
                                height: auto !important;
                            }
                        }
                    }
                    .product-item-details {
                        @include make-col(10);
                        
                        .product-item-inner {
                            @include make-row();
                        }
                        .product-item-name-block {
                            @include make-col-ready();
                            @include make-col(8);
                        }
                        .subtotal {
                            @include make-col-ready();
                            @include make-col(4);
                            text-align: right;
                        }
                        .options {
                            .toggle {
                                cursor: pointer;

                                span:after {
                                    @include svg-icon(1rem, 1rem, $icon-angle-down, $body-color);
                                    margin-left: map-get($spacers, 2);
                                }                                    
                            }
                            &.active {
                                .toggle {
                                    span:after {
                                        @include svg-icon(1rem, 1rem, $icon-angle-up, $body-color);
                                    }
                                }
                            }
                            .subtitle {
                                display: none;
                            }
                            .item-options {
                                @include font-size($font-size-sm);

                                &:after {
                                    content: '';
                                    display: table;
                                    clear: both;
                                }
                                dt {
                                    clear: left;
                                    float: left;
                                }
                                dd {
                                    float: left;
                                    margin-bottom: 0;
                                    margin-left: map-get($spacers, 2);
                                }
                            }
                        }
                    }
                }
                + .product-item {
                    margin-top: $spacer;
                }
            }
        }
        .table-totals {
            .table-caption {
                display: none;
            }
            .totals {
                th {
                    padding-left: 0;
                }
                .amount {
                    padding-right: 0;
                    text-align: right;
                }
            }
        }
    }
    .opc-block-shipping-information {
        .ship-to, .ship-via {
            .shipping-information-title {
                display: block;
                margin-bottom: $headings-margin-bottom;
                font-family: $headings-font-family;
                font-weight: $headings-font-weight;
                line-height: $headings-line-height;
                color: $headings-color;
                @include font-size($h4-font-size);
            }
        }
        .ship-to {
            padding-bottom: $spacer;
            margin-bottom: $spacer;
            border-bottom: 1px solid $gray-200;
        }
    }
    .form-shipping-address {
        padding-top: $spacer / 2;
    }
}
.checkout-success-wrapper {
    margin-top: $spacer;
    margin-bottom: $spacer;

    .checkout-success {
        .actions-toolbar {
            .action.primary {
                background: $white;
                @include button-outline-variant($btn-primary, $btn-primary);

                &:hover {
                    color: $white;
                }
            }
        }
    }
    .checkout-success-account {
        padding: $spacer;
        background: $gray-200;

        .action.primary {
            @extend .btn;
            @include button-variant($btn-cta, $btn-cta);
        }
    }
}

.field-tooltip {
    .label {
        display: flex;
        align-items: center;
    }
    .icon-info {
        @include svg-icon(1rem, 1rem, $icon-checkout-info);
    }
}
