.table {
    width: 100%;
    margin-bottom: $spacer;
    color: $table-color;
    background-color: $table-bg; // Reset for nesting within parents with `background-color`.
  
    th,
    td {
      padding: $table-cell-padding;
      padding-left: 0;
      vertical-align: top;
      border-top: $table-border-width solid $table-border-color;

      &.mark {
        padding: $table-cell-padding;
        background: none;
      }
    }
  
    thead th {
      vertical-align: bottom;
      border-bottom: (2 * $table-border-width) solid $table-border-color;
    }
  
    tbody + tbody {
      border-top: (2 * $table-border-width) solid $table-border-color;
    }
  }
