header {
    .top-wrapper {
        .top-usp {
            justify-content: space-between;
            .usp-block {
                .title {
                    color: $white;
                    font-size: $font-size-sm;
                }
            }
        }
    }

    .header {
        padding-bottom: $spacer;
        .header-sub {
            .logo-relative {
                .logo {
                    height: 100%;

                    img {
                        top: -9rem;
                    }
                }
            }
        }
    }
}

$icons: (
    truck:$icon-truck,
    phone:$icon-phone,
    credit-card:$icon-credit-card
    );

$iconWidth: $font-size-sm;

@each $class,
$icon in $icons {

    .top-wrapper {
        .top-usp {
            .usp-block {
                .icon {
                    &-#{$class} {
                        @include svg-icon($iconWidth, $iconWidth, $icon, $white);
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}
