.block-compare {
    margin-bottom: map-get($spacers, 5);

    @include media-breakpoint-down(sm) {
        display: none;
    }
    .block-title {
        .counter {
            color: $text-muted;
            font-size: $font-size-sm;
        }
    }
    .block-content {
        .product-items {
            padding: 0;
            list-style-type: none;
        }
    }
}
