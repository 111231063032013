header {
    margin-bottom: map-get($spacers, 3);

    @include media-breakpoint-down($main-breakpoint) {
        margin-bottom: 0;
    }

    .header {
        @include media-breakpoint-down($main-breakpoint) {
            display: none;
        }
    }

    .top-wrapper {
        padding: map-get($spacers, 2);
        margin-bottom: map-get($spacers, 3);

        ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                display: inline-block;
            }
        }
        
        @include media-breakpoint-down($main-breakpoint) {
            display: none;
        }
    }
}

.breadcrumbs-wrapper {
    margin-top: map-get($spacers, 3);
    min-height: $breadcrumb-height;
    margin-bottom: map-get($spacers, 3);

    .breadcrumb {
        flex-wrap: nowrap;
        position: relative;
        margin-bottom: 0;
        overflow-x: auto;
        white-space: nowrap;
        overflow-x: auto;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
    }
}
