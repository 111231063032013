.footer-social {
    text-align: center;

    .block-title {
        font-family: $headings-font-family;
        font-size: 2rem;
        text-align: left;
    }
    ul {
        justify-content: space-around;
        row-gap: map-get($spacers, 3);
    }

    li {
        a {
            display: flex;
            align-items: center;
            column-gap: map-get($spacers, 2);
            color: $white;
            text-decoration: none;
        }

        span:first-of-type {
            padding: map-get($spacers, 2);
            display: flex;
            justify-content: center;
            background-color: $white;
            border-radius: $rounded-pill;
        }
    }
}

$socialMedia: (
    facebook:$icon-social-facebook,
    twitter:$icon-social-twitter,
    pinterest:$icon-social-pinterest,
    youtube:$icon-social-youtube,
    instagram:$icon-social-instagram,
    blog:$icon-social-blog
);

$iconWidth: 1.25rem;
        
@each $platform, $icon in $socialMedia {
    .footer-social {
        li {
            span {
                &:first-of-type {
                    .icon {
                        &.social_#{$platform} {
                            @include svg-icon($iconWidth, $iconWidth, $icon, $primary);
                        }
                    }
                }
            }
        }
    }
}