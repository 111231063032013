.block-search {
    margin-bottom: 0;

    .form-group {
        margin-bottom: 0;
    }
    .actions {
        .search {
            &:before {
                content: ' ';
                @include svg-icon(1rem, 1rem, $icon-search, $white);
            }
            @include button-variant($btn-search, $btn-search);
        }
    }
}
.navbar-search {
    display: none;

    @include media-breakpoint-down($main-breakpoint) {
        display: block;
    }
    .form {
        @extend .container;
    }
}
