.checkout-cart-index {

    h1.page-title,
    h2.summary.title {
        @include font-size($h3-font-size);
        font-weight: $headings-font-weight;
        font-family: $font-family-base;
        color: $primary;
    }

    .cart-empty {
        margin-top: map-get($spacers, 4);
        margin-bottom: map-get($spacers, 4);
        padding: map-get($spacers, 4) 0 map-get($spacers, 3) 0;
        border: ($border-width * 2) solid $gray-200;
        text-align: center;
    }

    .cart-block {
        border: $border-width solid lighten($gray-100, 2);
        padding: $spacer;
        &.left {
            margin-bottom: 0;

            .page-title-wrapper {
                border-bottom-color: lighten($gray-100, 0.75);
            }
        }
    }
}

.cart-container {
    .cart-title {
        width: 100%;
        text-align: center;

        .cart-qoute-text {
            .block-title h2 {
                margin: map-get($spacers, 4) 0;
                color: $pink;
                font-weight: $font-weight-normal;
                @include font-size($h2-font-size);
            }
        }
    }

    .cart {
        .item-info {
            border-bottom: $border-width solid lighten($gray-100, 0.75);
            .product-item-details {
                .cart-price {
                    color: $gray-500;
                    font-size: 1rem;
                    font-weight: $font-weight-normal;
                }

                .product-item-name {
                    margin-bottom: map-get($spacers, 2);
                    font-size: $font-size-lg;

                    a {
                        color: $black;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                .item-options {
                    display: flex;
                    flex-direction: column;

                    .item-option {
                        border: 0;
                    }
                }

                .spinbox {
                    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.1);
                    -moz-box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.1);
                    -webkit-box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.1);

                    .input-group {
                        border: 0;

                        .input-group-btn {
                            button.btn-qty {
                                .icon-minus {
                                    @include svg-icon(0.75rem, 0.75rem, $icon-minus, $secondary);
                                }

                                .icon-plus {
                                    @include svg-icon(0.75rem, 0.75rem, $icon-plus, $secondary);
                                }
                            }
                        }
                    }
                }
            }
        }

        &.actions {
            display: flex;
            padding: $spacer 0;
            align-items: center;
            justify-content: space-between;
            .action {
                &.update {
                    color: $secondary;
                    border-color: $secondary;
                    font-size: $font-size-base;
                    padding: map-get($spacers, 2) 0.75rem;
                    float: none;

                    &:hover {
                        background-color: $secondary;
                        color: $white;
                    }

                    span {
                        &::before {
                            @include svg-icon(1rem, 1rem, $icon-refresh, $secondary);
                        }
                    }
                }

                &.continue {
                    font-size: $font-size-base;
                    font-weight: $font-weight-light;
                    color: $gray-500;
                    @include media-breakpoint-down(sm) {
                        display: block;
                    }
                }
            }
        }
    }

    .totals-wrapper {
        .totals-inner {
            margin-bottom: map-get($spacers, 4);

            .cart-totals {
                .table-caption {
                    padding: map-get($spacers, 2) 0;
                }

                .totals,
                .shipping {
                    font-size: 1rem;

                    th {
                        font-weight: $font-weight-normal !important;
                    }

                    &.grand {
                        font-size: $font-size-lg;

                        th {
                            font-weight: $font-weight-bold;
                        }
                    }
                }
            }

            .checkout-methods-items {
                .item {
                    button.checkout {
                        padding: 0.75rem 2rem;
                        background-color: $primary;
                        border-color: $primary;
                        font-weight: $font-weight-light;
                        text-transform: uppercase;

                        &:hover {
                            background-color: darken($primary, 7.5);
                        }

                        &:focus {
                            outline: 5px auto -webkit-focus-ring-color;
                            outline-offset: -2px;
                            box-shadow: none;
                        }
                    }
                }
            }
        }

        .payment-options-wrapper {
            .title {
                margin-bottom: $spacer;
                font-size: 1rem;
                font-weight: $font-weight-normal;
            }

            .payment-icons {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;

                .payment-icon {
                    width: 22%;
                }
            }
        }
    }

    .discount {
        margin-bottom: map-get($spacers, 5);

        .title {
            background-color: lighten($gray-100, 4.75);
            justify-content: flex-start;
            column-gap: $spacer;

            strong {
                color: $gray-500;
                font-size: 1rem;
                font-weight: $font-weight-light;
            }

            .icon{
                display: flex;
                padding: map-get($spacers, 2);
                align-items: center;
                justify-content: center;
                background-color: $white;
                border-radius: $rounded-pill;

                &::after {
                    @include svg-icon(1.5rem, 1.5rem, $icon-percent, $gray-500);
                }
            }

            &::after {
                margin-left: auto;
            }
        }

        .content {
            background-color: lighten($gray-100, 4.75);

            .field {
                label.label {
                    display: none;
                }

                .control {
                    display: flex;
                    align-items: stretch;

                    .input-text {
                        padding: 0.375rem 0.75rem;
                        height: 2.5rem;
                        width: 70%;
                        border-color: $gray-200;
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
                        -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
                        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);

                        &:focus {
                            border: ($border-width * 2) solid $secondary;
                            box-shadow: none;
                        }
                    }

                    .actions-toolbar {
                        height: 2.5rem;
                        width: 30%;

                        .primary {
                            height: 2.5rem;
                            background-color: lighten($gray-100, 4.75);
                            color: $secondary;
                            border-color: $secondary;
                            border-width: ($border-width * 2);
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;

                            &:hover {
                                background-color: $secondary;
                                color: $white;
                            }

                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .gift-wrap-container {
        .tocart {
            display: flex;
            align-items: center;
            column-gap: map-get($spacers, 1);
            float: right;

            .icon {
                &-gift {
                    @include svg-icon(1rem, 1rem, $icon-gift, $white);
                }
            }
        }
    }
}

.block {
    &.crosssell {
        .block-title {
            display: block;
            margin-bottom: $headings-margin-bottom * 2;
            @include font-size($h3-font-size);
        }
    }
}
.cart-container {
	.mobile-summary {
		padding: 1.071em;
		border-bottom: 1px solid lighten($gray-100, 0.75);
        justify-content: space-between;
        display: none;
        @include media-breakpoint-down(md) {
            display: flex;
        }

		&.affix {
            position: fixed;
            display: flex;
			width: 100%;
			background: $white;
			top: 0;
			left: 0;
            right: 0;
			z-index: 3000;
		}
		h5 {
			margin: 0;
			font-weight: 600;
            font-family: $font-family-base;
		}
		.price {
			font-weight: 500;
			font-size: 1.143em;
			color: $gray-400;
        }

        .checkout {
            &.mobile {
                display: block;
                background-color: $orange;
                border-color: $orange;
                width: 100%;

                &:focus {
                    border: 1px solid $white;
                    outline: 5px auto -webkit-focus-ring-color;
                    outline-offset: -2px;
                    box-shadow: none;
                }

                &:focus, &:hover, &:active, &:not(:disabled):not(.disabled):active {
                    background-color: lighten($orange, 10);
                    border-color: lighten($orange, 10);
                    box-shadow: none;
                }
            }
        }
	}
}

.cart-crosssell-wrapper {
    margin-top: 8rem;
    .crosssell {
        .block-title {
            margin-bottom: 0;
            strong {
                text-align: start;

                .title {
                    @include font-size($h2-font-size);
                }
                .subtitle {
                    &::after {
                        top: -130%;
                        left: 17%;
                        transform: rotateZ(270deg) rotateY(180deg);
                    }
                }
            }
        }
    }
}
