/*------------------------------------------------------------*\
    OFFCANVAS
\*------------------------------------------------------------*/

@mixin offcanvas {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    float: none;
    margin: 0 !important;
    width: 100%;
    max-width: $offcanvas-max-width;
    background: $offcanvas-bg;
    border: 0;
    z-index: $zindex-offcanvas;
    transform: translateX(-100%);
    overflow-y: scroll;
    -webkit-overflow-scrolling: auto;
    transition: $offcanvas-transition;

    &::-webkit-scrollbar {
        width: 0;
    }

    &.expanded {
        transform: translateX(0);
    }

    &-left {
        &.expanded {
            transform: translateX(0);
        }
    }

    &-right {
        left: auto;
        right: 0;
        transform: translateX(100%);

        &.expanded {
            transform: translateX(0);
        }
    }

    &-top {
        transform: translateY(-100%);

        &.expanded {
            transform: translateY(0);
        }
    }

    &-bottom {
        transform: translateY(100%);

        &.expanded {
            transform: translateY(0);
        }
    }

    .offcanvas-body {
        position: relative;
        padding: $spacer;
    }
}

.offcanvas-heading {
    display: none;
    padding: $spacer;
    background: $offcanvas-heading-bg;
    color: $offcanvas-heading-color;

    .title {
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        line-height: $headings-line-height;
        @include font-size($offcanvas-font-size);
    }

    .close {
        color: $offcanvas-heading-color;
    }
}

.offcanvas-nonscroll {
    height: 100%;
    overflow: hidden;
}

.offcanvas {
    @include offcanvas;

    .offcanvas-heading {
        display: block;
    }
}

@include media-breakpoint-down(md) {
    .offcanvas-md {
        @include offcanvas;

        .offcanvas-heading {
            display: block;
        }
    }
}

@include media-breakpoint-down(sm) {
    .offcanvas-sm {
        @include offcanvas;

        .offcanvas-heading {
            display: block;
        }
    }
}

@include media-breakpoint-down(xs) {
    .offcanvas-xs {
        @include offcanvas;

        .offcanvas-heading {
            display: block;
        }
    }
}

// Modal background
.offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zindex-offcanvas-backdrop;
    width: 100vw;
    height: 100%;
    background-color: $offcanvas-backdrop-bg;

    // Fade for backdrop
    &.fade {
        opacity: 0;
    }

    &.in {
        opacity: $offcanvas-backdrop-opacity;
    }
}
