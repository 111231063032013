.message {
    &.cookie {
        background: $primary;

        p {
            font-size: $font-size-sm !important;
        }

        .actions {
            font-size: $font-size-sm !important;

            .allow {
                background: $primary;

                &:hover {
                    color: $primary;
                }
            }
        }
    }
}
