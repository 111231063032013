footer.footer {
    .footer-about {
        margin-bottom: map-get($spacers, 4);
        padding: map-get($spacers, 4);
        background-color: $white;

        .footer-about {
            border: $border-width solid darken($gray-100, 2.25);
            border-radius: $border-radius;

            &>.row {
                flex-direction: row;
            }

            .footer-block {
                .block-title {
                    color: $gray-900;
                }

                p {
                    color: $gray-500;
                    font-size: $font-size-sm;
                }
            }

            .feedback-company {
                .title {
                    color: $gray-900;
                    @include font-size($h4-font-size);
                    font-weight: $headings-font-weight;
                }

                .text {
                    color: $gray-500;
                    font-size: $font-size-sm;
                }

                .scores {
                    font-weight: $font-weight-bold;
                }

                .logo {
                    float: right;
                }
            }
        }
    }

    .block-newsletter {
        margin-bottom: map-get($spacers, 4);
        padding-bottom: map-get($spacers, 4);

        .title {
            color: $gray-900;
            @include font-size($h4-font-size);
            font-weight: $headings-font-weight;
        }

        .sub-title {
            color: $primary;
            @include font-size($h5-font-size);
            font-weight: $headings-font-weight;
            line-height: 0.75;
        }

        .form-control {
            height: calc(1.5em + 1.5rem + 2px);
            border-right: none;
            border: none;
            background-color: lighten($gray-100, 2.75);
            color: $gray-300;
            font-size: $font-size-sm;
            box-shadow: none;

            &::placeholder {
                color: $gray-300;
            }
        }

        .input-group-btn {
            button.subscribe {
                height: 100%;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    .footer-middle-wrapper {
        background-color: $primary;

        .footer-block {
            .block-title {
                span {
                    color: $white;
                    @include font-size($h4-font-size);
                }
            }

            .block-content {
                p {
                    font-size: $font-size-sm;
                    margin-bottom: map-get($spacers, 2);
                }
            }
        }
    }

    .footer-bottom-wrapper {
        .copyright {
            color: $gray-500;
            font-size: $font-size-sm;
        }
    }
}



.footer-block {
    .block-title {
        &::after {
            display: none !important;
        }
        h3 {
            @include font-size($h4-font-size);
        }
    }

    &.footer-social{
        .block-title {
            @include font-size($h4-font-size);
            font-weight: $headings-font-weight;
        }
        ul.social {
            margin: 0 0 $spacer;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include media-breakpoint-down($main-breakpoint) {
                justify-content: flex-start;
                column-gap: $spacer;
            }

            li {
                &+li {
                    margin-top: 0;
                }

                &::before {
                    display: none;
                }

                a {
                    display: flex;
                    background-color: $white;
                    border-radius: $rounded-pill;

                    .blog {
                        display: flex;
                        justify-content: center;
                        width: 1.25rem;
                        height: 1.25rem;
                        color: $primary;
                        font-size: $font-size-sm;
                        @include media-breakpoint-down($main-breakpoint) {
                            height: 1rem;
                            width: 1rem;
                        }
                    }

                    $socialMedia: (facebook:$icon-social-facebook,
                        twitter:$icon-social-twitter,
                        pinterest:$icon-social-pinterest,
                    );

                    $iconWidth: 1.25rem;
                    @include media-breakpoint-down($main-breakpoint) {
                        $iconWidth: 1rem;
                    }
                    @each $platform,
                    $icon in $socialMedia {
                        .icon {
                            &-#{$platform} {
                                @include svg-icon($iconWidth, $iconWidth, $icon, $primary);
                            }
                        }
                    }
                }
            }
        }

    }

    .contact-info {
        list-style: none;
        padding-inline-start: 0;

        li {
            a {
                @include font-size($h5-font-size);
                font-weight: 500;
                &::before {
                    display: none !important;
                }
            }

            div {
                display: flex;
                align-items: center;
            }

            .icon {
                margin-right: map-get($spacers, 1);

                &-phone {
                    @include svg-icon(1.25rem, 1.25rem, $icon-phone-solid, $white);
                }

                &-envelope {
                    @include svg-icon(1.25rem, 1.25rem, $icon-envelope-open, $white);
                }
            }
        }


        .opening-hours {
            font-size: ($font-size-sm * 0.85);
            font-style: italic;
            margin-bottom: map-get($spacers, 4);
        }
    }
}
