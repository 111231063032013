.cms-home {
    h1, .page-title-wrapper .page-title {
        color: $pink;
    }
    h2 {
        color: $secondary;
    }

    h1, h2, .page-title-wrapper .page-title {
        @include font-size($h1-font-size);
        font-family: $headings-font-family;
        font-weight: $font-weight-normal;
    }

    nav.navbar {
        margin-bottom: map-get($spacers, 4);
    }

.home-hero {
    .hero-banner {
        margin-bottom: 0.875rem;

        img {
            border-radius: $border-radius-sm;
        }
    }
}

.home-block {
    &.top-list {
        margin-bottom: map-get($spacers, 3);
        padding: map-get($spacers, 3);
        box-shadow: 0 2px 6px 2px rgba(0,0,0,0.05);
        border-radius: 0.625rem;

        @include media-breakpoint-down($main-breakpoint) {
            margin-bottom: map-get($spacers, 3);
        }

        .title {
            color: $primary;
            font-family: $headings-font-family;
            @include font-size($h2-font-size);
        }

        .products-list {
            padding: 0;
            list-style: none;

            .product-item {
                display: flex;
                align-items: center;
                &-photo {
                    display: inline-block;
                    margin-left: map-get($spacers, 3);
                    margin-right: map-get($spacers, 3);

                    .product-image-photo {
                        width: 50px;
                        min-height: 4.5rem;
                        aspect-ratio: auto 50 / 72;
                    }

                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }

                &-details {
                    display: inline-block;

                    @include media-breakpoint-down(sm) {
                        margin-left: map-get($spacers, 2);
                        margin-right: map-get($spacers, 2);
                    }
                    .price-box {
                        color: $pink;
                        .price-label, .old-price {
                            display: none;
                        }
                    }
                }

                &:last-child {
                    padding-bottom: map-get($spacers, 4);
                    border-bottom: 1px solid $gray-100;
                }

                &+.product-item {
                    margin-top: map-get($spacers, 1);
                }
            }
            .product-number {
                position: relative;
                display: inline-block;
                @include font-size($h4-font-size);
                font-weight: $font-weight-bold;

                @include media-breakpoint-down($main-breakpoint) {
                    font-size: $font-size-base;
                }

                &:after {
                    @include svg-icon(3rem, 3rem, $icon-star, $primary);

                    @include media-breakpoint-down($main-breakpoint) {
                        @include svg-icon(2rem, 2rem, $icon-star, $primary);
                    }
                }
                .number {
                    position: absolute;
                    top: 45%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: $white;
                }
            }
        }

        .topper {
            display: block;
            text-align: right;

            &:after {
                @include svg-icon(1rem,1rem, $icon-arrow-right, $primary);
                margin-left: map-get($spacers, 2);
                vertical-align: middle;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.home-blocks.row {
    .col-md-4 {
        .home-block {
            .hero-image {
                border: 2px dashed $primary;
                border-radius: $border-radius;
            }
        }
    }
    .home-block {
        .hero-image {
            img {
                border-radius: $border-radius;
            }
        }
    }
    .home-logo {
        margin-top: map-get($spacers, 3);
    }
}
.home-logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.feedback {
    margin: map-get($spacers, 3) 0;
    padding: map-get($spacers, 3);
    box-shadow: 0 2px 6px 2px rgba(0,0,0,0.05);
    border-radius: $border-radius;

    @include media-breakpoint-down(sm) {
        margin-top: map-get($spacers, 3);
    }

    .block-title {
        font-family: $headings-font-family;
        color: $secondary;
        font-weight: $font-weight-normal;
        line-height: 1;
        @include font-size($h1-font-size);
    }

    .stars {
        color: $yellow;
        font-size: 1.571em;
    }
    .text {
        font-size: 0.857em;
    }
    .logo {
        text-align: right;
        margin-top: -1rem ;
        @include media-breakpoint-down(lg) {
            text-align: left;
            margin-top: 1rem;
        }

        img {
            max-width: 9.286em;
        }
    }

    .rating-summary .result-result {
        width: 100%;
    }
    .rating-summary .rating-result > span {
        overflow: visible;
    }

    .rating-summary .rating-result:before {
        content: '';
    }

    .rating-summary .rating-result > span {
        width: 8.5rem !important;
        @include svg-icon(1.5rem, 1.5rem, $icon-star, $yellow);
        background-repeat: repeat-x;
        background-position: left;

        &::before {
            content: '';
        }
    }
    .scores {
        a {
            color: $primary;
        }
    }
}

.home-products {
    padding-bottom: map-get($spacers , 4);

    .block-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: $headings-font-family;
        @include font-size($h1-font-size);
        color: $pink;

        &.block-title-mobile {
            display: none;
        }

        @include media-breakpoint-down($main-breakpoint) {
            line-height: 1;
            @include font-size($h2-font-size);
            &.block-title-desktop {
                display: none;
            }
            &.block-title-mobile {
                display: inline-block;
            }
        }
    }
    .block-link {
        font-family: $font-family-base;
        order: 2;
        font-size: $font-size-base;

        @include media-breakpoint-down(sm) {
            margin-top: map-get($spacers, 3);
            font-size: $font-size-sm;
        }

        a {
            text-decoration: none;
            &:after {
                @include svg-icon(0.75rem,0.75rem, $icon-arrow-right, $primary);
                margin-left: map-get($spacers, 2);

                @include media-breakpoint-down($main-breakpoint) {
                    @include svg-icon(0.5rem,0.5rem, $icon-arrow-right, $primary);
                    margin-left: map-get($spacers, 1);
                }
            }
        }
    }

    .products-grid {
        .product-item {
            .product-image-wrapper {
                img {
                    border: 1px solid $gray-100;
                }
            }

            &-info {
                border: 0 !important;
            }
        }
    }
}

.widget {
    .block-content {
        .product-item {
            margin: 1rem 0;

            .product-item-info {
                padding: 0 0.5rem;
                border: 1px solid lighten($gray-100, 2.5);
            }

            &:hover {
                .product-item-info {
                    border-color: transparent;
                }
            }
        }
    }
}

.page-title-wrapper {
    margin-bottom: 1.75rem;
}
}
