.block-reorder {
    margin-bottom: map-get($spacers, 5);

    .product-items {
        padding: 0;
        list-style-type: none;

        .product-item {
            +.product-item {
                margin-top: map-get($spacers, 2);
            }
        }
    }
    @include media-breakpoint-down($main-breakpoint) {
        display: none;
    }
}
.sales-order-view,
.sales-order-invoice,
.sales-order-shipment {
    .order-actions-toolbar {
        margin-bottom: map-get($spacers, 3);

        .action {
            &.print {
                float: right;
            }
        }
    }
    .order-links {
        padding: 0;
        margin-bottom: 0;
        list-style-type: none;
        border-bottom: 1px solid $gray-200;

        li {
            display: inline-block;
            margin-bottom: -1px;

            > strong,
            > a {
                display: block;
                border: 1px solid $gray-200;
                padding: map-get($spacers, 2) map-get($spacers, 3);
            }
            > strong {
                border-bottom: 1px solid #fff;
            }
            > a {
                background: $gray-100;
            }
            + li {
                margin-left: -1px;
            }
        }
    }
}
.order-details-items {
    margin-bottom: map-get($spacers, 3);
    padding: map-get($spacers, 3);
    padding-left: 0;

    .order-title {
        margin-bottom: map-get($spacers, 2);
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        line-height: $headings-line-height;
        color: $headings-color;
        @include font-size($h4-font-size);

        a {
            margin-left: map-get($spacers, 4);
            @include font-size($font-size-sm);
        }
    }
    .print-all {
        display: block;
        margin-bottom: map-get($spacers, 2);
    }
}
.order-items {
    .table-caption {
        display: none;
    }
    .items-qty {
        padding: 0;
        list-style-type: none;
    }
    .item-options {
        padding-left: $spacer;
        color: $gray-700;
        @include font-size($font-size-sm);

        &:after {
            content: '';
            display: table;
            clear: both;
        }
        dt {
            clear: left;
            float: left;
        }
        dd {
            float: left;
            margin-bottom: 0;
            margin-left: map-get($spacers, 2);
        }
    }
}
.block-order-details-view {
    .block-title {
        margin-bottom: map-get($spacers, 2);
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        line-height: $headings-line-height;
        color: $headings-color;
        @include font-size($h4-font-size);
    }
}
.sales-order-view {
    .order-status {
        display: inline-block;
        margin-bottom: map-get($spacers, 2);
        font-weight: 700;
    }
    .order-date {
        margin-bottom: map-get($spacers, 2);
    }
    .payment-method {
        dt {
            font-weight: 400;
        }
    }
}
.sales-order-print, .sales-order-printshipment {
    .logo {
        display: block;
        padding: map-get($spacers, 4) 0;
    }
    .page-title-wrapper {
        padding: map-get($spacers, 4) 0;
    }
    .items-qty {
        list-style-type: none;
        padding-inline-start: 0;
    }
    .payment-method {
        dt {
            font-weight: 400;
        }
    }
    .copyright {
        display: block;
        padding-bottom: map-get($spacers, 4);
        text-align: center;
    }
}
