.catalog-category-view {
    h1.page-title {
        font-weight: $headings-font-weight;
    }
    h2 {
        color: $secondary;
        font-weight: $font-weight-normal;
        font-size: 2rem;
    }
}

.products-grid {
    .__fbcw_ps__widget-product-summary {
        .__fbcw_ps__widget-product-summary {
            &__review-count {
                visibility: hidden;
            }
            &__stars-holder {
                margin-right: -25px !important;
            }
        }
    }

    .product-col, .widget-product-item.product-item {
        position: relative;
        padding: 0;
        border: 0.5rem solid transparent;

        &.configurable-product {
            &:hover,
            &:focus {
                border-bottom-color: transparent;
                @include border-radiuses($border-radius, $border-radius, 0, 0);
                z-index: 10;
            }
        }

        &:hover,
        &:focus {
            border: 0.5rem solid lighten($primary, 15);
            border-radius: $border-radius;

            .product-item-configuration {
                display: inline-block;
                left: -17px;
                right: -17px;
            }
            .product-item-info {
                border: 1px solid transparent;
            }
        }
    }

    .product-item {
        padding: 0.5rem;
        margin-bottom: map-get($spacers, 3);

        &:hover, &:focus {
            border-radius: $border-radius-sm;

            @include media-breakpoint-down(sm) {
                box-shadow: 2px 0px 8px 0px lighten($gray-100, 2.5);
            }
        }


        &-info {
            position: relative;
            border: 1px solid lighten($gray-100, 2.6);
        }

        &-photo {
            justify-content: center;
        }

        &-details {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            min-height: 5.25rem;
            padding-bottom: map-get($spacers, 3);
            text-align: center;

            .product-reviews-summary {

                .rating-summary {
                    .rating-result {
                        width: 5rem;

                        &::before {
                            @include svg-icon(100%, 1rem, $icon-star-sharp, $gray-100);
                            background-repeat: repeat-x;
                            background-position: left;
                            left: 0;
                        }
                    }
                }

                .reviews-actions {
                    display: none;
                }
            }
        }

        &-name {
            justify-content: center;
            padding: 0 map-get($spacers, 2);
            min-height: 2.75rem;
            font-weight: $font-weight-normal;
        }

        &-link {
            text-align: center;
            font-weight: $font-weight-normal;
        }

        .product-image-wrapper {
            img {
                //max-width: 100%;
                //aspect-ratio: auto 298 / 430;
                border-radius: $border-radius-sm;
            }
        }

        .price-box {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: map-get($spacers, 2);
            margin-bottom: map-get($spacers, 3);
            font-weight: $font-weight-normal;
            color: $pink;
            text-align: center;

            .price-label {
                display: none;
            }

            .old-price {
                color: $gray-500 !important;
                font-size: ($font-size-base * 0.9);
                text-decoration: line-through;
            }
        }

        &-configuration {
            padding: 0 0.5rem;
            padding-top: 0;
            display: none;
            position: absolute;
            left: -1.75rem;
            right: -1.75rem;
            z-index: 10;
            background-color: $white;
            border: 0.5rem solid lighten($primary, 15);
            border-top: 0;
            @include border-radiuses(0, 0, $border-radius, $border-radius);

            @include media-breakpoint-down($main-breakpoint) {
                left: -1.3rem;
                right: -1.3rem;
            }


            @include media-breakpoint-down(sm) {
                left: -1.25rem;
            }
            &>div {
                .swatch-attribute {
                    padding: 0.75rem 0;
                    margin: 0;
                    border-top: 1px dashed $gray-200;

                    .swatch-attribute-options {
                        display: flex;
                        flex-wrap: wrap;
                        row-gap: 0.15rem;
                    }

                    &.color {
                        .swatch-option {
                            margin: 0 0.15rem;
                            width: 2rem !important;
                            height: 1.25rem !important;
                            max-width: 100%;
                            min-width: 2rem;
                            border-radius: 0;
                        }
                    }

                    &.size {
                        .swatch-option {
                            font-size: 0.7rem;
                            color: $gray-400;
                            border: none;
                        }
                    }
                }
            }
        }

        &-actions {
            margin: 1rem 0 0.25rem;

            .actions-primary {
                color: $green;

                .stock.available {
                    &::before {
                        @include svg-icon(1rem, 1rem, $icon-check, $green);
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: map-get($spacers, 1);
                    }
                }
            }

            .actions-secondary {}

            .towishlist,
            .tocompare {
                @extend .btn;
            }

            .towishlist {
                @include button-variant($btn-wishlist, $btn-wishlist);

                &:before {
                    @include svg-icon(1rem, 1rem, $icon-wishlist, $body-color);
                }
            }

            .tocompare {
                @include button-variant($btn-compare, $btn-compare);
                display: none;

                &:before {
                    @include svg-icon(1rem, 1rem, $icon-compare, $body-color);
                }
            }
        }
    }
}

.toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-down($main-breakpoint) {
        justify-content: flex-start;
        column-gap: 2rem;
    }

    .pages,
    .modes,
    .toolbar-amount {
        display: none;
    }

    .toolbar-limiter {
        @include media-breakpoint-down(sm) {
            display: none;
        }

        .input-group {
            .input-group-prepend {
                .input-group-text {
                    padding-left: 0;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {

        .btn-primary.btn-layered,
        button.btn-light {
            width: 10em;
            background-color: $white;
            border: 2px solid $secondary;
            color: $secondary;
        }

        .btn-layered {
            &::before {
                @include svg-icon(0.8rem, 0.8rem, $icon-slider-h, $primary);
                vertical-align: middle;
            }
        }

        button.btn-light {
            &::before {
                @include svg-icon(0.8rem, 0.8rem, $icon-sort, $primary);
                vertical-align: middle;
            }
        }
    }

    .dropdown {
        &.show {
            .dropdown-toggle {
                box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
                -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
                -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);

                .icon {
                    @include svg-icon(1rem, 1rem, $icon-angle-up, $primary);
                }
            }
        }

        .dropdown-toggle {
            background-color: $white;
            display: flex;
            align-items: center;
            text-transform: capitalize;
            color: $secondary;
            border: 2px solid lighten($secondary, 25);

            .icon {
                @include svg-icon(1rem, 1rem, $icon-angle-down, $primary);
                vertical-align: middle;
            }

            &::before {
                display: none;
            }
        }

        .dropdown-menu {
            .active {
                background-color: $secondary;
            }

            .dropdown-item {
                &::first-letter {
                    text-transform: capitalize;
                }
            }
        }
    }

    .input-group {

        .input-group-prepend,
        .input-group-append {
            .input-group-text {
                color: $gray-700;
                font-weight: 500;
            }
        }
    }

    .toolbar-sorter {
        .input-group {
            .input-group-append {
                margin-left: map-get($spacers, 2);
                align-items: center;

                @include media-breakpoint-down($main-breakpoint) {
                    display: none;
                }

                a.btn {
                    background-color: $white;
                    border: none;
                    box-shadow: none;

                    &.sort-asc {
                        @include svg-icon(0.85rem, 0.85rem, $icon-sort-amount-down, $primary);
                    }

                    &.sort-desc {
                        @include svg-icon(0.85rem, 0.85rem, $icon-sort-amount-up, $primary);
                    }
                }
            }
        }
    }
}

.category-view {
    margin-bottom: $spacer;

    .page-title-wrapper {
        margin-bottom: 1.75rem;

            .page-title {
            color: $primary;
        }
    }

    .category-description {
        p, a.read-more {
            display: inline;
        }
    }
}

.toolbar-footer {
    margin-bottom: map-get($spacers, 4);

    .toolbar {
        justify-content: space-between;

        .btn-layered {
            display: none;
        }

        .toolbar-amount {
            display: block;
            font-size: $font-size-sm;
        }

        .toolbar-limiter {
            display: none;
        }
    }

    .pages {
        display: block;
    }
}

.product-item-photo {
    position: relative;
    margin: 0;

    @include media-breakpoint-down($main-breakpoint){
        .product-image-container {
            padding: 0 map-get($spacers, 2);
        }
    }

    .product-item-attribute {
        position: absolute;
        right: 0;
        bottom: 10%;

        @include media-breakpoint-down($main-breakpoint) {
            right: map-get($spacers, 2);
        }

        @include media-breakpoint-down(md) {
            right: 0;
        }
        .product-label {
            padding: 0.2rem 0.4rem;
            display: flex;
            align-items: center;
            column-gap: 0.25rem;
            font-weight: $font-weight-normal;
            font-size: $font-size-sm;
            color: $white;
            background-color: $primary;

            .product-label-text {
                white-space: nowrap;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: -0.41em;
                border-top: 10px solid;
                border-bottom: 10px solid;
                border-color: $primary;
                border-left: 5px solid transparent !important;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: -1em;
                right: -0.929em;
                border-top: 1em solid $primary;
                border-color: darken($primary, 12);
                border-right: 1em solid transparent !important;
                z-index: 99;
            }

            .arrow-corner {
                position: absolute;
                top: -1px;
                right: -0.929em;
                bottom: 0;
                border-left: 1em solid $primary;
                border-top: 1.9em solid transparent !important;
                border-bottom: 0 solid transparent !important;
                border-right: 0 solid transparent !important;
                z-index: 99;

            }

            .icon {
                vertical-align: baseline;
            }
        }
    }
}

$labelColors: (
    discount: $purple,
    red: $pink,
    purple: $purple,
    orange: $orange,
    green: $green,
    blue: $blue,
    black: $black,
    yellow: $yellow
);

$iconClasses: (
    star: $icon-star-sharp,
    gift: $icon-gift,
    heart: $icon-heart,
    award: $icon-award,
    thumbs-up: $icon-thumbs-up
);
$iconSize: 0.6rem;

@each $labelClass, $bgColor in $labelColors {
    .product-item-photo {
        .product-item-attribute {
            .product-label {
                &.#{$labelClass} {
                    background-color: $bgColor;

                    &::before {
                        border-color: $bgColor;
                    }

                    &::after {
                        border-top: 1em solid $bgColor;
                        border-color: darken($bgColor, 12);
                    }

                    .arrow-corner {
                        border-left: 1em solid $bgColor;
                    }
                }
            }
        }
    }
}

@each $iconClass, $iconValue in $iconClasses {
    .product-label {
        .icon {
            &-#{$iconClass} {
                @include svg-icon($iconSize, $iconSize, $iconValue, $white);
            }
        }
    }
}
