/*------------------------------------------------------------*\
    CART
\*------------------------------------------------------------*/

.checkout-cart-index {
	.cart-empty {
		border: $border-width solid $gray-200;
		text-align: center;
	}
}

.cart-container {
    margin-top: map-get($spacers, 3);

	.mobile-summary {
		padding: map-get($spacers, 3) 0;
        border-bottom: $border-width solid $gray-200;
        
		h5 {
			margin: 0;
			font-weight: $font-weight-bold;
		}
		.price {
			color: $gray-600;
			font-weight: $font-weight-bold;
		}
    }
    .cart-block {
		padding: map-get($spacers, 4);
        border: $border-width solid $gray-400;
        @include border-radius($card-border-radius);

		@include media-breakpoint-down($main-breakpoint) {
			padding: 0em;
			margin-bottom: map-get($spacers, 3);
			border: 0 transparent;
		}
		&.left {
			margin-bottom: map-get($spacers, 3);
		}
		.page-title-wrapper {
			border-bottom: $border-width solid $gray-200;

			h1, h2 {
				margin-bottom: map-get($spacers, 2);
			}
			h2 {
				margin: 0 0 map-get($spacers, 3);
			}
			@include media-breakpoint-down($main-breakpoint) {
				h1 {
					font-size: $h3-font-size;
				}
			}
		}
		h2.summary {
			padding-bottom: map-get($spacers, 3);
			margin: 0 0 map-get($spacers, 3);
			border-bottom: $border-width solid $gray-200;
		}
		.action.update {
			@include button-outline-variant($primary, $white);
            float: right;
			font-size: $h6-font-size;
            font-weight: $font-weight-bold;
            
			&.update {
                span {
                    position: relative;
                    display: flex;
                    align-items: center;
                }

				span::before {
					@include svg-icon(1rem, 1rem, $icon-refresh, $primary);
					margin-right: 0.25rem;
				}

                span::after {
                    @include svg-icon(1rem, 1rem, $icon-refresh, $white);
                    position: absolute;
                    left: 0;
                    opacity: 0;
                    transition: opacity 0.3s ease;
                }

				&:hover {
                    span::after {
                        opacity: 1;
                    }
				}
			}
			@include media-breakpoint-down(sm) {
				float: none;
			}
		}
		.action.continue {
			@extend .btn;
			@include button-variant(transparent, transparent);
			padding-left: 0;
			color: $gray-600;
			font-size: $h6-font-size;
			font-weight: $font-weight-bold;
			text-align: left;
			display: inline-flex;
			align-items: center;

			&:before {
				content: ' ';
				@include svg-icon(1rem, 1rem, $icon-angle-left);
			}
			&:hover {
				text-decoration: none;
				color: $gray-300;
			}
			@include media-breakpoint-down(sm) {
				display: none;
			}
		}
    }
    .cart {
		.item-info {
            margin: map-get($spacers, 3) 0;
			padding-bottom: map-get($spacers, 3);
            border-bottom: $border-width solid $gray-200;

            &:last-child {
				margin-bottom: map-get($spacers, 3);

				@include media-breakpoint-down($main-breakpoint) {
					margin-bottom: map-get($spacers, 3);
				}
            }
            .cart-price-block {
                padding: $btn-padding-y 0;
				font-weight: $font-weight-bold;
			}
            .product-item-name {
				font-weight: $font-weight-bold;
            }
            .item-options {
				padding-left: 0;
				margin-left: 0;
				list-style: none;

				.item-option {
					display: inline-block;
					padding-right: map-get($spacers, 1);
					border-right: $border-width solid $gray-400;
					color: $gray-600;

					&:last-child {
						border-right: 0;
					}
				}
            }
            .spinbox {
                .qty {
                    max-width: $spinbox-width;
                    text-align: center;
                }
            }
            .subtotal {
				float: right;
				padding: map-get($spacers, 2) map-get($spacers, 3);

				.title {
					color: $gray-600;
					font-weight: $font-weight-bold;
				}
				.price {
					font-weight: $font-weight-bold;
				}
			}
            .actions-toolbar {
				position: absolute;
				top: 0;
				right: map-get($spacers, 3);
				padding-left: 0;
				list-style: none;

				li {
					display: inline-block;
					
					a {
						color: $gray-500;
						text-decoration: none;

						&:hover {
							color: $gray-500;
						}
						span {
							display: none;
						}
					}
				}
				.action-edit, .action-delete {
					@extend .btn;
					
					&:before {
						content: ' ';
					}
					span {
						display: none;
					}
				}
				.action-edit {
						@include svg-icon(1rem, 1rem, $icon-edit, $body-color);
				}
				.action-delete {
						@include svg-icon(1rem, 1rem, $icon-trash, $body-color);
				}
			}
        }
    }
    .cart-products-toolbar-bottom {
		margin-top: map-get($spacers, 3);

		.pages {
			display: block;
		}
    }
    .discount {
        background: $gray-100;
        @include border-radius($card-border-radius);

        .title {
            padding: map-get($spacers, 4);
            background: $gray-200;
            cursor: pointer;
			@include border-radius($card-border-radius);
			display: flex;
			align-items: center;
			justify-content: space-between;

            &:after {
                content: ' ';
				@include svg-icon(1rem, 1rem, $icon-angle-down);
            }
        }
        &.active {
            .title {
                @include border-bottom-radius(0);

                &:after {
                    @include svg-icon(1rem, 1rem, $icon-angle-up);
                }   
            }
        }
        .content {
            padding: map-get($spacers, 3);
        }
    }
    .table {
        margin: map-get($spacers, 3) 0;
        
        caption {
            caption-side: top;
        }
		@include media-breakpoint-down($main-breakpoint) {
			margin-bottom: map-get($spacers, 3);
		}
    }
    .cart-totals {
        .mark,
        .amount {
            padding: 4px;
            padding-left: 0;
            border-top: 0 transparent;
            background: none;
        }
        .amount {
            color: $gray-700;
            text-align: right;
        }
        .totals {
            &.shipping {
                font-size: $font-size-sm;

                &.incl {
                    .value {
                        display: none;
                    }
                }
            }
        }
        .grand {
            &.incl, &.excl {
                font-size: $font-size-sm;
            }
            &.totals {
                .mark,
                .amount {
                    font-size: $font-size-lg;
                }
            }
        }
    }
    .checkout-methods-items {
		margin-bottom: 0;
		padding-left: 0;
		list-style: none;
    }
    button.checkout {
		width: 100%;
		@extend .btn-lg;
		@include button-variant($btn-cta, $btn-cta);
    }
    .block.shipping {
		display: none;
	}
}
.block {
 	&.crosssell {
		.block-title {
			display: block;
        	font-family: $headings-font-family;
        	font-weight: $headings-font-weight;
        	line-height: $headings-line-height;
        	color: $headings-color;
		}
 	}
}
