.products-grid {
    .product-item {
        margin-bottom: 2rem;
        
        &-photo {
            display: flex;
            margin-bottom: 1rem;
        }
        &-name {
            display: flex;
        }
        .price-box {
            margin-bottom: $headings-margin-bottom;
        }
        &-actions {
            .actions-primary, .actions-secondary {
                display: inline-block;
            }
            .tocart {
                @include button-variant($btn-addtocart, $btn-addtocart);
            }
            .towishlist, .tocompare {
                @extend .btn;

                span {
                    display: none;
                }
            }
            .towishlist {
                @include button-variant($btn-wishlist, $btn-wishlist);
                &:before {
                    content: ' ';
                    @include svg-icon(1rem, 1rem, $icon-wishlist, $body-color);
                }
            }
            .tocompare {
                @include button-variant($btn-compare, $btn-compare);
                &:before {
                    content: ' ';
                    @include svg-icon(1rem, 1rem, $icon-compare, $body-color);
                }
            }
        }
    }
}
.toolbar {
    margin-bottom: map-get($spacers, 3);

    .modes, .toolbar-amount, .toolbar-limiter, .toolbar-sorter {
        display: inline-block;
        
        .input-group-text {
            border: 0;
            background: none;
        }
    }
    .toolbar-amount {
        margin-bottom: 0;
    }
}
.toolbar-footer {
    .modes, .toolbar-sorter {
        display: none;
    }
}
.offcanvas-sort {
    padding: 0;
    @include border-radius(0);
}
