footer.footer {
    padding: $padding-y 0 0;
    background-color: $white;

    .footer-middle-wrapper {
        padding: 2rem 0;
        background-color: $primary;
        color: $white;

        a {
            color: $white;
        }

        @include media-breakpoint-down(sm) {
            .footer-block {
                .block-title {
                    &.label-collapse {
                        display: flex !important;
                        align-items: center;
                        justify-content: space-between;
                    } 
                    span {
                        display: inline-block;
                    }
                    &::after {
                        display: inline-block;
                    }
                    &[aria-expanded='true'] {
                       &::after {
                           @include svg-icon(1rem, 1rem, $icon-minus, $white);
                       }
                    }
                    &[aria-expanded='false'] {
                       &::after {
                           @include svg-icon(1rem, 1rem, $icon-plus, $white);
                       }
                    }
                }
            }
        }
    }

    .footer-bottom-wrapper {
        padding: 2rem 0;

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .copyright {
                font-size: $font-size-sm;
            }
        }
    }
}

.footer-block {
    .block-title {
        margin-bottom: map-get($spacers, 2);
        font-family: $headings-font-family;
        font-weight: $font-weight-normal;
        line-height: $headings-line-height;
        color: $headings-color;
        @include font-size($h1-font-size);

        &.label-collapse {
            &:after {
                @include svg-icon(1rem, 1rem, $icon-angle-down, $body-color);
                float: right;
            }

            &.collapsed {
                &:after {
                    @include svg-icon(1rem, 1rem, $icon-angle-up, $body-color);
                }
            }
        }
    }

    .block-content {
        ul {
            padding: 0;
            list-style-type: none;

            li {
                &::before { 
                    @include svg-icon(1rem, 1rem, $icon-angle-right, $white);
                    vertical-align: middle;
                    background-position: left;                    
                }
                &+li {
                    margin-top: map-get($spacers, 2);
                }
            }
        }

        p > a {
            &::before {
                margin-right: map-get($spacers, 2 );
                vertical-align: middle;
            }
        }
        a[href^='tel'] {
            &::before {
                @include svg-icon(1.4rem, 1.4rem, $icon-phone-alt, $white);
                transform: scaleX(-1);
            }

            br {
                content: "";
                display: block;
                font-size: 100%;
                height: 0.5em;
            }
        }
    
        a[href^='mailto'] {
            &::before {
                @include svg-icon(1.4rem, 1.4rem, $icon-envelope, $white);
            }
        }
    }
}
footer.footer .footer-middle-wrapper {
    background-color: #157bc1;
    color: $white;
}
.footer-payments {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    img {
        width: 2.5rem;
    }

    @include media-breakpoint-down(sm) {
       gap: 0.5rem;
       justify-content: flex-end;
    }
}

.footer-sc-logo {
    position: relative;
    top: -0.25rem; // Misschien geen scheef logo gebruiken?
    margin-right: 1rem;
}
footer {
    .footer-about {
        padding: 2.143rem 0 0;
        background: $secondary;
        color: $white;

        &>.container>.row {
            flex-direction: row-reverse;
            @include media-breakpoint-down($main-breakpoint) {
                flex-direction: row;
            }
        }
    }
    .footer-about-social .block-title {
        font-family: $headings-font-family;
        @include font-size($h1-font-size);
    }
    .social-item a {
        color: $white;
    }
}
.footer-about-social {
    .social-item {
        margin: 0.5rem 0;

        a {
            display: flex;
            font-size: 0.8rem;
            align-items: center;
        }
    }
}

.footer-about {
    .footerseomenu {
        margin-top: $spacer;
        // display: none;

        // @include media-breakpoint-down($main-breakpoint) {
        //     display: block;
        // }
        .btn-footer-block {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &::after {
                @include svg-icon(1rem, 1rem, $icon-plus, $white);
            }
            &.collapsed {
                &::after {
                    @include svg-icon(1rem, 1rem, $icon-minus, $white);
                }   
            }
            .btn-total {
                font-weight: $font-weight-base;
                @include font-size($h2-font-size);

            }
        }
        .block-content {
            p {
                a {
                    color: $white;
                }
            }
        }
    }
}


.footer-middle-wrapper h3 {
    font-family: $headings-font-family;
    @include font-size($h1-font-size);
    margin-top: 0;
}
.feedback-company {
    .block-title {
        h3 {
            font-family: $headings-font-family;
            @include font-size($h1-font-size);
            margin-top: 0;
        }
    }
    .stars {
        color: $yellow;
        font-size: 1.286rem;
    }
    .logo-fbc {
        img {
            max-width: 8.000rem;
            margin-top: map-get($spacers, 3)
        }
    }

    .rating-summary .rating-result {
        width: 6.7rem;
        display: inline-block;
        position: relative;
        vertical-align: middle;

        &::before {
            position: absolute;
            left: 0;
            content: '';
        }
        
        &>span {
            width: 100% !important; // to show 5 stars 
            overflow: visible;
            display: block;
            @include svg-icon(1.2rem, 1.2rem, $review-star, $yellow);
            font-size: 1rem;
            background-repeat: repeat-x;
            background-position: left;
            
            &::before { 
                content: ''
            }
        }
    }
}
