.message {
    &.cookie {
        position: fixed;
        bottom: 1.5rem;
        left: 1.5rem;
        padding: map-get($spacers, 4);
        z-index: 1000;
        background: $secondary;
        color: #fff;
        max-width: 26.5rem;
        border-radius: $border-radius;

        a {
            text-decoration: underline;
            color: #fff;
        }
        .actions {
            margin-top: map-get($spacers, 4);

            .allow {
                border: 2px solid #fff;
                color: #fff;
                background: $secondary;

                &:hover {
                    background: #fff;
                    color: $secondary;
                }
            }
        }


        @include media-breakpoint-down($main-breakpoint) {
            left: 0;
            right: 0;
            bottom: 0;
            padding: $spacer;
            font-size: $font-size-sm;
            border-radius: 0;

            .actions {
                .btn {
                    font-size: $font-size-sm;
                }
            }
        }
    }
}
