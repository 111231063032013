.checkout-index-index {
    .progress-authetication-wrapper {
        .opc-progress-bar {
            li {
                color: $gray-300;
                font-size: $font-size-sm;

                &._active {
                    span {
                        border-bottom-color: $primary;
                    }
                }

                &>a, &._active {
                    color: $gray-500;
                    font-size: $font-size-sm;
                }
            }
        }
    }

    .checkout-shipping-address, .checkout-shipping-method {
        .step-title {
            color: $gray-900;
            font-family: $headings-font-family;
            font-weight: $font-weight-bold;
        }

        .step-content {
            label {
                &.label {
                    color: $gray-500;
                }
            }

            .control {
                .input-text, .select {
                    background-color: lighten($gray-100, 2.75);
                    box-shadow: 0 2px 1px 0 rgba(0,0,0,0.1);
                    -moz-box-shadow: 0 2px 1px 0 rgba(0,0,0,0.1);
                    -webkit-box-shadow: 0 2px 1px 0 rgba(0,0,0,0.1);
                    border-width: 0;
                    border: none !important;   
                }
            }
            .form-text {
                font-size: $font-size-sm;
            }
        }
    }

    .checkout-shipping-method {
        .methods-shipping {
            .col-method, .col-carrier, .col-price {
                color: $gray-500;
                font-size: $font-size-sm;
            }
        }
    }

    .opc-sidebar {
        .opc-block-summary {
            .items-in-cart {
                .title, .minicart-items .product-item .product-item-details {
                    color: $gray-500;
                    font-size: $font-size-sm;
                }
            }

            .table-totals {
                .totals, .totals-tax {
                    font-size: $font-size-sm;
                    color: $gray-500;
                    font-weight: $font-weight-semibold;
                }

                .totals-tax {
                    .mark {
                        padding-left: 0;
                    }
                    .amount {
                        padding-right: 0;
                        text-align: end
                    }
                }
            }
        }
    }

    .shipping-information {
        .ship-to, .ship-via {
            color: $gray-500;

            .shipping-information-title {
                font-size: $font-size-lg;
                font-weight: $font-weight-semibold;
            }

            .shipping-information-content {
                font-size: $font-size-sm;
            }
        }
    }
}