.cms-page-view {
    h2 {
        color: $secondary;
        font-weight: $font-weight-light;
    }

    p {
        a {
            color: $link-color;
            text-decoration: underline;
            &:hover {
                color: $link-hover-color;
            }
        }
    }

    &.cms-qa {
        $info: #5bc0de;
        $darkenedInfo: #31b0d5;
        
        p {
            a {
                color: $white;
                background-color: $info;
                border-color: darken($info, 1);
                &:hover {
                    background-color: $darkenedInfo;
                }
            }
        }
    }
}