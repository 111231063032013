.dropdown-menu ul {
    list-style-type: none;
    padding: 0;
}
.navbar .navbar-nav li {
    @include media-breakpoint-down($main-breakpoint) {
        padding: 0;
    }

}
.widget.block.block-category-link-inline a {
    @include media-breakpoint-down($main-breakpoint) {
        padding-left: 0;
    }
}
.dropup, .dropright, .dropdown, .dropleft {
    position: unset;
}
.navbar-expand-lg .navbar-nav .dropdown-menu {
    width: 100%;
    border: unset;
}
.menu-item:hover {
    @include media-breakpoint-down($main-breakpoint) {
        background-color: lighten($secondary, 45);
    }
    
    .menu-link-normal {
        background-color: lighten($secondary, 45);
        text-decoration: none;

        @include media-breakpoint-down($main-breakpoint) {
            background-color: inherit;
        }
    }   
}    
.dropdown{
    b.dropdown-toggle{
        @include media-breakpoint-down($main-breakpoint) {
            width: 2.5rem;
            height: 2rem;
            background-color: $secondary;
            border-radius: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &::after {
            @include media-breakpoint-down($main-breakpoint) {
                @include svg-icon(0.875rem, 0.875rem,  $icon-plus, $white);
                margin-left: 0;                
                border: 0px;
                vertical-align: middle;
            }
        }
        
    }
    &.show {
        b.dropdown-toggle:after {
            @include media-breakpoint-down($main-breakpoint) {
                @include svg-icon(0.875rem, 0.875rem,  $icon-minus, $white);
            }
        }
    }
}

.navbar{
    #navbar-offcanvas{
        @include media-breakpoint-only(lg) {
            margin: 0;
        }    
        .navbar-nav {
            .menu-item {
                &:last-child {
                    display: none;
                    
                    @include media-breakpoint-down($main-breakpoint) {
                        display: block;
                    }
                }
            }
        }
    } 

    .dropdown {
        @include media-breakpoint-down($main-breakpoint) {
            position: relative;
        }
    }
    
    .dropdown-menu {
        margin-top: 0;
        
        @include media-breakpoint-down($main-breakpoint){
            margin-top: 0.625rem;
            margin-bottom: -0.5rem;
        }
        
        @include media-breakpoint-up($main-breakpoint){
            padding: 2rem;
        }
        &>.container {
            &>.row {
                width: 100%;
            }
        }
        p {
            strong { 
                font-weight: $font-weight-bold;
            }
        }
        ul li {
            a {
                padding: 0.35rem 0;
                font-size: $font-size-base;
                text-decoration: none;
                font-weight: $font-weight-base;
                text-shadow: none;
                transition: all 50ms linear;

                &:hover {
                    font-weight: $font-weight-bold;
                }

                &::before{    
                    @include svg-icon(0.8rem, 1.2rem, $icon-angle-right, $primary);
                    vertical-align: middle;
                    background-position: left;                
                }
            }

            &+li {
                margin-top: map-get($spacers, 1 );
            }
        }
    }
}

.widget.block-static-block {
    p {
        a {
            @include media-breakpoint-down($main-breakpoint) {
                padding-left: 0;
                padding-right: 0;
                font-size: $font-size-base;
            }
        }
    }
}

.navbar-backdrop {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $zindex-modal-backdrop;
    background-color: $modal-backdrop-bg;
    opacity: $modal-backdrop-opacity;
}