.checkout-index-index {
    background: linear-gradient(to right, $white 50%, lighten($gray-100, 3.5) 50%);
    @include media-breakpoint-down(sm) {
        background: $white;
    }
    .discount-code {
        display: none;
    }
    .sub-header {
        text-align: center;

        @include media-breakpoint-down(sm) {
            display: none;
        }
        .logo {
            img {
                object-fit: contain;
            }
        }
    }

    .progress-authetication-wrapper {
        @include media-breakpoint-down(sm) {
            justify-content: center;
            .opc-progress-bar {
                .opc-progress-bar-item {
                    &-cart, 
                    &-shipping, 
                    &-payment {
                        border: ($border-width * 2) solid $primary;
                        border-radius: $rounded-pill;
                        padding: 0.35rem;
                    }

                    &+.opc-progress-bar-item {
                        &::before {
                        @include svg-icon(1rem, 1rem, $icon-angle-right, $primary);
                        }
                    }

                    a, span {
                        border-color: $primary !important;
                        &::before {
                            width: 1.5rem;
                            height: 1.5rem;
                            fill: $primary;
                        }
                    }

                    &._active, &._complete {                
                        .opc-progress-bar-item-cart {
                            &:before {
                                @include svg-icon(1rem, 1rem, $icon-checkout-cart, $primary);
                            }
                        }
                        .opc-progress-bar-item-shipping {
                            &:before {
                                @include svg-icon(1rem, 1rem, $icon-checkout-shipping, $primary);
                            }
                        }
                        .opc-progress-bar-item-payment {
                            &:before {
                                @include svg-icon(1rem, 1rem, $icon-checkout-payment, $primary);
                            }
                        }
                    }
                }
            }
        }
        .opc-progress-bar {
            margin-bottom: map-get($spacers, 4);

            li._active {
                span {
                    border-bottom-color: $orange;
                }
            }
        }

        .authentication-wrapper {

            .block-new-customer,
            .block-customer-login {
                .block-title {
                    font-family: $font-family-sans-serif;
                }

                .block-content {
                    .actions-toolbar {
                        .action-guest {
                            width: 100%;
                            background-color: $orange;
                            border-color: $orange;
                            color: $white;

                            &:hover,
                            &:focus,
                            &:active,
                            &:not(:disabled):not(.disabled):active {
                                background-color: darken($orange, 5);
                                color: $white;
                            }

                            &:focus,
                            &:active,
                            &:not(:disabled):not(.disabled):active {
                                box-shadow: none;
                            }

                            &+.action-guest {
                                margin-top: map-get($spacers, 2);
                            }
                        }
                    }
                }
            }

            .block-customer-login {
                .actions-toolbar {
                    display: flex;
                    flex-direction: column;

                    .primary,
                    .secondary {
                        width: 100%;
                        text-align: center;
                    }

                    .primary {
                        .action-login {
                            background-color: $white;
                            border-color: $blue;
                            color: $blue;

                            &:hover,
                            &:focus,
                            &:active,
                            &:not(:disabled):not(.disabled):active {
                                background-color: $blue;
                                color: $white;
                            }

                            &:focus,
                            &:active,
                            &:not(:disabled):not(.disabled):active {
                                box-shadow: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .checkout-shipping-address,
    .checkout-shipping-method,
    .opc-sidebar,
    .payment-group {
        .step-title {
            color: $orange;
            font-family: $font-family-sans-serif;
            font-weight: $font-weight-normal;
            @include font-size($h3-font-size);
        }
    }

    .checkout-shipping-address, .billing-address-form {
        margin-bottom: map-get($spacers, 5);

        .step-content, .address {
            .form, .field {

                .label {
                    font-weight: $font-weight-bold;
                }

                .control {

                    .input-text,
                    .select {
                        border: ($border-width * 2) solid $input-border-color;

                        &:focus {
                            box-shadow: none;
                            border-color: $blue;
                        }
                    }
                }

                .field-error,
                .mage-error {
                    position: relative;
                    top: auto;
                }
            }
        }

        .fieldset {
            .row {
                div {
                    padding: 0;

                    input {
                        width: 90%;
                    }
                }
            }
        }
    }

    .checkout-shipping-method {
        .step-content {
            .methods-shipping {
                .table-checkout-shipping-method {
                    tr {
                        td {
                            border-top: none;
                        }
                    }
                }
            }
        }
    }

    .opc-sidebar {
        .opc-block-summary {
            .mobile-cart-content {
                display: none;
            }

            .collapse {
                display: block;

                .items-in-cart {
                    .title {
                        &::after {
                            @include svg-icon(1rem, 1rem, $icon-angle-down, $gray-700);
                            float: right;
                            vertical-align: middle;
                        }
                    }

                    &.active {
                        .title {
                            &::after {
                                @include svg-icon(1rem, 1rem, $icon-angle-up, $gray-700);
                            }
                        }
                    }

                    .minicart-items-wrapper {
                        .product-item {
                            border: 0;
                            &+.product-item {
                                margin-top: 0;
                                padding-top: $spacer;
                                border-top: $border-width solid darken($gray-100, 2.25);
                            }
                            &:hover {
                                box-shadow: none;
                            }

                            .product-item-inner {
                                .row {
                                    .product-item-name-block {
                                        padding: 0;
                                    }

                                    .subtotal {}
                                }
                            }
                        }
                    }
                }

                .table {
                    tr {
                        border-color: lighten($gray-500, 3.5);
                    }

                    th.mark {
                        padding-left: 0;
                    }

                    td.amount {
                        padding-right: 0;
                        text-align: right;
                    }
                }
            }
        }
    }

    .actions-toolbar {
        .primary {
            .continue[data-role="opc-continue"], .checkout {
                background-color: $green;
                box-shadow: $box-shadow-lg;
                padding: map-get($spacers, 2) map-get($spacers, 5);
                font-size: 1rem;
                text-transform: uppercase;

                &:hover {
                    background-color: darken($green, 3);

                }
            }
        }
    }
    .delivery_options {
        margin-bottom: $spacer;
        width: 85%;
        background-color: $gray-100;
        border: $border-width solid $gray-200;
        border-radius: $border-radius-sm;

        .btn-group {
            width: 100%;
            .btn {
                background-color: $white;
                padding: 1rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-weight: $font-weight-light;

                &.button-active {
                    background-color: $gray-100;

                    &:hover {
                        background-color: $gray-100;
                    }
                }

                &:hover {
                    background-color: lighten($gray-200, 4);
                }
            }
        }

        .delivery {
            .days {
                ul {
                    padding: 0;
                    list-style: none;

                    li {
                        label {
                            padding: $spacer;
                            display: flex;
                            align-items: center;
                            column-gap: $spacer;
                            font-weight: $font-weight-bold;

                            .tig-postnl-delivery-radio {
                                width: 1.25rem;
                                height: 1.25rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

.checkout-index-index {
    .opc-payment {
        .payment-methods {
            .payment-group {
                .payment-method {
                    &._active {
                        background-color: lighten($gray-100, 2.75);
                    }
                    .payment-method-title {
                        display: flex;
                        align-items: center;
                        column-gap: $spacer;

                        span {
                            font-size: 1rem;
                        }
                    }

                    .payment-method-content {
                        .payment-method-issuers {
                            label {
                                width: 100%;
                            }
                        }
                        .payment-method-billing-address {
                            .checkout-billing-address {

                                .choice {
                                    label {
                                        font-weight: $font-weight-bold;
                                    }
                                }

                                .billing-address-details {
                                    display: none;
                                }
                                .fieldset {
                                    .billing-address-form {
                                        margin-bottom: 0;
                                        padding: 0;
                                        padding-right: $spacer;
                                        background-color: lighten($gray-100, 2.75);
                                    }
                                }
                            }
                        }
                        .checkout-agreements {
                            .checkout-agreement {
                                label {
                                    .action {
                                        color: $blue;
                                        font-weight: $font-weight-bold;

                                        &:hover {
                                            color: darken($blue, 5);
                                            text-decoration: underline;
                                            text-decoration-color: darken($blue, 5);
                                        }

                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
    .opc-block-shipping-information {
        .shipping-information {
            .shipping-information-title {
                font-family: $font-family-sans-serif;
                font-weight: $font-weight-light;
                @include font-size($h4-font-size);

                .action-edit {
                    span {
                        display: none;
                    }
                    &::after {
                        @include svg-icon(1.25rem, 1.25rem, $icon-edit, $gray-700);
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}

#recaptcha-checkout-place-order-wrapper, hr {
    display: none;
}

.checkout-onepage-success {
    .sub-header {
        @include media-breakpoint-down(sm) {
            display: none;
        }
        .row {
            justify-content: center;
        }
        .logo {
            img {
                object-fit: contain;
            }
        }
    }
    .checkout-success-account {
        background-color: lighten($gray-100, 1);
        border-radius: $border-radius;

        .primary {
            &.action {
                background-color: $orange;
                border-color: $orange;

                &:hover, &:focus, &:active,
                &:not(:disabled):not(.disabled):active {
                    background-color: darken($orange, 5);
                    border-color: darken($orange, 5);
                    color: $white;
                }

                &:focus, &:active,
                &:not(:disabled):not(.disabled):active, 
                &:not(:disabled):not(.disabled):active:focus {
                    box-shadow: none;
                }

            }
        }
    }
}

.checkout-index-index {

    .tig-postnl-field-group {
        display: flex;
        align-items: flex-end;

        .field {
            &:last-child {
                margin: 0 0 20px 0;
            }
            span {
                max-width: 100%;
            }

            .control {
                margin-top: 0;
            }
        }       
    }
}