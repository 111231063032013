// button {
//     @extend .btn;
// }
.action {
    &.primary {
        @extend .btn-primary;
    }
    &.secondary {
        @extend .btn-secondary;
    }
}
button {
    @extend .btn;

    &.primary {
        @extend .btn-primary;
    }
    &.secondary {
        @extend .btn-secondary;
    }
}
