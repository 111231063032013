.checkout-cart-index {
    .cart-container {
        .cart {
            .item-info {
                .item-options {
                    .item-option {
                        color: $gray-300;
                        font-size: $font-size-sm;
                    }
                }
            }

            &.actions {
                .action {
                    &.continue {
                        color: $gray-300;
                        font-size: $font-size-sm;
                        font-weight: $font-weight-base;

                        &::before {
                            @include svg-icon($font-size-sm, $font-size-sm, $icon-angle-left, $gray-300);
                        }
                    }

                    &.update {
                        background-color: transparent;
                        color: $green;
                        border-color: $green;
                        font-size: $font-size-sm;

                        &:hover {
                            background-color: $green;
                            color: $white;
                        }

                        span {
                            &::before {
                                @include svg-icon($font-size-sm, $font-size-sm, $icon-refresh, $green);
                            }
                        }
                    }
                }
            }

            .item-info  {
                .product-item-details {
                    .spinbox {
                        .input-group {
                            .input-group-btn {
                                button {
                                    &.btn-qty {
                                        .icon-minus {
                                            @include svg-icon(0.75rem, 0.75rem, $icon-minus, $primary)
                                        }

                                        .icon-plus {
                                            @include svg-icon(0.75rem, 0.75rem, $icon-plus, $primary)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .cart-block {
            h2 {
                &.summary {
                    color: $gray-900;
                    font-family: $headings-font-family;
                }
            }
        }

        .totals-wrapper {
            .totals-inner {
                .table-wrapper {
                    .totals {
                        font-size: $font-size-sm;
                    }
                }
                .checkout-methods-items {
                    .item {
                        button {
                            &.checkout {
                                background-color: $green;
                                border-color: $green;
                                font-size: $font-size-base;
                                font-weight: 400;

                                &:focus {
                                    background-color: darken($green, 10);
                                }
                            }
                        }
                    }
                }
            }

            .title {
                color: $gray-500;
                font-weight: 500;
            }
        }

        .gift-wrap-container {
            .tocart {
                font-size: $font-size-sm;

                .icon {
                    @include svg-icon($font-size-sm, $font-size-sm, $icon-gift, $white);
                }
            }
        }
    }
    .cart-crosssell-wrapper {
        .crosssell {
            .block-title {
                .subtitle {
                    &::after {
                        background-image: none;
                    }
                }
            }
        }
    }
}
