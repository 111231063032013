.customer-account-login, 
.customer-account-forgotpassword, 
.customer-account-create {
    .page-header {
        margin-bottom: 4.5rem;

        .top-wrapper {
            min-height: 2.125rem;

            .btn-header-block {
                display: none;
            }
        }
    }

    .column.main {
        margin-bottom: map-get($spacers, 3);
        >div {
            margin: auto;
        }

        .row {
            justify-content: center;
        }

        .panel {
            .panel-heading {
                .panel-title {
                    color: $primary;
                    @include font-size($h2-font-size);
                }
            }

            .panel-body {
                .form {
                    margin-bottom: $spacer;
                }
            }
        }
    }

    .form-group {
        label {
            margin-bottom: map-get($spacers, 1);
            font-weight: $font-weight-bold;
        }

        .form-control {
            border: ($border-width * 2) solid $input-border-color;
            border-radius: 2rem;

            &:focus {
                border-color: $secondary;
                box-shadow: none;
            }
        }

        .mage-error, #password-strength-meter-container {
            position: relative;
            margin-top: map-get($spacers, 1);
            top: auto;

            &.password {
                &-weak, &-none {
                    span {
                        color: $red;
                    }
                }

                &-very-strong {
                    span {
                        color: $green;
                    }
                }
            }
        }

        .mage-error {
            color: $red;
        }
    }

    .btn {
        &:focus, &:not(:disabled):not(.disabled):active {
            box-shadow: none;
            outline: 0.25rem auto $secondary;
            outline-offset: -0.125rem;
        }
    }

    .btn-primary {
        color: $white;
        background-color: $secondary;
        border-color: darken($secondary, 5);

        &:hover, &:active, &:not(:disabled):not(.disabled):active {
            background-color: darken($secondary, 5);
            border-color: darken($secondary, 7.5);
        }
    }

    .btn-default {
        border: $border-width solid $gray-200;
        font-weight: $font-weight-light;

        &:hover, &:active, &:not(:disabled):not(.disabled):active {
            background-color: darken($gray-100, 3);
            border-color: darken($gray-300, 1);
        }
    }
}

.customer-account-logoutsuccess {
    .navbar {
        margin-bottom: 4.5rem;
    }
    .page-title-wrapper{
        .page-title {
            color: $gray-900;
            font-family: $headings-font-family;
            @include font-size($h1-font-size);
            margin-bottom: map-get($spacers, 4);
        }
    }
}
.customer-account-forgotpassword,
.customer-account-create,
.customer-account-login {
    .page-title-wrapper {
        margin-bottom: map-get($spacers, 4);
        text-align: center;

        .page-title {
            font-family: $headings-font-family;
        }
    }
    .block-customer-login {
        .block-title {
            @include heading($h2-font-size);
            color: $primary;
        }
        strong {
            font-weight: $headings-font-weight;
        }
    }
}

.customer-account-create {
    .form-create-account {
        &>div {
            margin: auto;
        }
        .create {
            &.info {
                display: flex;
                align-items: center;
                column-gap: map-get($spacers, 4);
            }

            .field {
                width: 100%;
            }
        }
    }

    .button-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.customer-account-index,
.account {
    @include media-breakpoint-down($main-breakpoint) {
        .sidebar-main {
            margin-bottom: map-get($spacers, 4);
        }
    }
    .page-main {
        margin-top: map-get($spacers, 5);
    }
    .block-dashboard-info, 
    .block-dashboard-addresses, 
    .block-dashboard-orders, 
    .block-reviews-dashboard {
        margin-bottom: map-get($spacers, 5);
        padding: $spacer;
        border: $border-width solid lighten($gray-100, 2.75);
        border-radius: $border-radius;
        
        .block-title {
            padding-bottom: 0.75rem;
            margin-bottom: 0.75rem;
            display: flex;
            align-items: center;
            column-gap: map-get($spacers, 1);
            font-family: $font-family-sans-serif;
            font-weight: $font-weight-normal;
            @include font-size($h4-font-size);
            color: $secondary;
            border-bottom: $border-width solid lighten($gray-100, 2.75);

            .icon-user-circle {
                @include svg-icon(1.15rem, 1.15rem, $icon-user-circle, $gray-700);
            }

            .icon-address-book {
                @include svg-icon(1.15rem, 1.15rem, $icon-address-book, $gray-700);
            }
            .action {
                margin-left: auto;
                font-size: $font-size-base;
                font-weight: $font-weight-light;
                
                >div {
                    display: flex;
                    align-items: center;
                    column-gap: map-get($spacers, 1);

                    .icon-edit {
                        @include svg-icon(1rem, 1rem, $icon-edit, $secondary);
                    }
                }

                &:hover {
                    div {
                        color: darken($secondary, 5);
                        text-decoration: underline;
                        .icon-edit {
                            @include svg-icon(1rem, 1rem, $icon-edit, darken($secondary, 5));
                        }
                    }
                }
            }
        }
        .box {
            + .box {
                margin-top: $spacer;
            }
        }
        .block-content {
            .box-title {
                display: block;
                margin-bottom: $spacer;
            }
            .items {
                padding-left: $spacer;

                li {
                    + li {
                        margin-top: map-get($spacers, 2);
                    }
                }
            }
        }
        .table {
            margin-bottom: 0;
            
            > thead, > tbody {
                > tr  {
                    > th {
                        border-bottom: 0;
                    }
                    > td {
                        border-top: 0;
                    }
                }
            }
        }
    }

    .account-nav {
        margin-top: $spacer; 

        &+.account-nav {
            display: none
        }

        h4 {
            @include font-size($h2-font-size);
            color: $pink;
            font-weight: $font-weight-normal;
        }
        
        .nav {
            &.nav-pills {
                display: block;
            }
            &.items {
                flex-direction: column;
            }
            &.item {
                flex-direction: column;
            }
            > li {
                > a, > strong {
                    padding: 0.625rem $spacer;
                    border-bottom: 1px solid lighten($gray-100, 2.75);
                    border-radius: $border-radius;
                    color: $gray-700;
    
                    &:hover, &:focus {
                        background: lighten($gray-100, 2.75);
                        border-bottom: 1px solid transparent;
                        text-decoration: none;
                    }
                }
            }
            > .current {
                > strong, > a {
                    background: lighten($gray-100, 2.75);
                    font-weight: $font-weight-light;
                    border-right: 0.5rem solid $primary;
                    border-top: 1px solid transparant;
                    border-bottom: 1px solid transparent;
                }
            }
        }
    }

    &.customer-address-form, 
    &.customer-account-edit {
        .page-title {
            margin-bottom: map-get($spacers, 5);
        }
        .form-address-edit, .form-edit-account {
            h2, .legend {
                margin-bottom: map-get($spacers, 3);
                padding-bottom: map-get($spacers, 1);
                font-family: $font-family-sans-serif;
                font-weight: $font-weight-light;
                @include font-size($h3-font-size);
                border-bottom: $border-width solid lighten($gray-100, 2.75);
                color: $secondary;
            }

            label, .label {
                font-weight: $font-weight-bold;
            }

            .checkbox {
               label {
                   font-weight: $font-weight-light;
               } 
            }

            .control, .form-group {
                input, select {
                    border-color: $gray-200;
                    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
                    -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
                    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);

                    &:focus, &:focus-visible {
                        outline: $blue auto 1px;
                    }

                    &:disabled {
                        background-color: lighten($gray-100, 2.75);
                    }
                }

                &.region {
                    display: block !important;
                }
            }
        }

        .btn-secondary {
            background-color: $primary;
            border-color: $primary;
            color: $white;

            &:hover, &:focus, &:active {
                background-color: darken($orange, 7.5);
                border-color: darken($orange, 7.5);
            }
            &:focus, &:active, &:not(:disabled):not(.disabled):active {
                box-shadow: none;
                outline: 0.25rem auto $secondary;
                outline-offset: -0.125rem;
            }
            span {
                display: flex;
                align-items: center;
                column-gap: map-get($spacers, 1);
                .icon-save {
                    @include svg-icon(0.875rem, 0.875rem, $icon-save, $white);
                }
            }
        }
    }

    &.customer-address-index {
        .box {
            .box-title, .action {
                display: flex;
                align-items: center; 
                column-gap: map-get($spacers, 1);
                .icon-truck{
                    @include svg-icon(1rem, 1rem, $icon-truck, $gray-900)
                }
                .icon-edit {
                    @include svg-icon(0.85rem, 0.85rem, $icon-edit, $gray-900)
                }
            }
            .action {
                width: fit-content;
                border: $border-width solid $gray-200;

                &:hover, &:focus, &:active {
                    background-color: darken($gray-100, 3);
                    border-color: darken($gray-300, 0.2);
                    box-shadow: none;
                }
            }
        }

        .block-addresses-default {
            margin-bottom: map-get($spacers, 4);
            padding-bottom: 1.25rem;
            border-bottom: $border-width solid lighten($gray-100, 2.75);

            .box-address-billing {
                .icon-file-text {
                    @include svg-icon(1rem, 1rem, $icon-file-text, $gray-900)
                }
            }
        }

        .block-addresses-list {
            .primary {
                background-color: $primary;
                border-color: $primary;
                color: $white;
    
                &:hover, &:focus, &:active {
                    background-color: darken($orange, 7.5);
                    border-color: darken($orange, 7.5);
                }
                &:focus, &:active, &:not(:disabled):not(.disabled):active {
                    box-shadow: none;
                    outline: 0.25rem auto $secondary;
                    outline-offset: -0.125rem;
                }
            }
        }
    }

    &.sales-order-history {
        .message {
            &.empty {
                padding-left: 0;
                background-color: $white;
                border-color: $white;
                color: $gray-900;
            }
        }
        .table {
            thead {
                th {
                    border-color: lighten($gray-100, 1);
                }
            }
            td {
                border-color: lighten($gray-100, 1);
            }
        }
        .toolbar {
            .pager {
                .limiter {
                    display: flex;
                    align-items: center;
                    column-gap: map-get($spacers, 2);

                    .limiter-label {
                        font-weight: $font-weight-light;
                    }
                    .limiter-text {
                        white-space: nowrap;
                    }
                }
            }
        }
        .actions-toolbar {
            display: none;
        }
    }
}