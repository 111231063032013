nav {
    &.navbar {
        #navbar-offcanvas {
            .navbar-nav {
                .menu-item {
                    a {
                        color: $gray-900;
                    }
                }
            }
        }
    }
}