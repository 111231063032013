$navbar-font-size: 0.9375rem;

header nav.navbar {
    margin-bottom: 0;
    padding-bottom: 0;
    background-color: $white;
    background-image: none;

    #navbar-offcanvas {
        margin-bottom: 0;
        margin-left: 0;
        padding-bottom: 0;
        border-bottom: 1px solid darken($gray-100, 2.25);

        .navbar-nav {
            @include media-breakpoint-down($main-breakpoint) {
                flex-wrap: nowrap;
            }

            .fa.fa-star,
            .icon.icon-star {
                margin-left: 0;
                @include svg-icon(1rem, 1.2rem, $icon-star, $red);
                vertical-align: text-top;
            }

            .menu-item {
                @include media-breakpoint-down($main-breakpoint) {
                    padding: 0;
                }

                .menu-link-normal {
                    padding: map-get($spacers, 2) 0.55rem;
                    &>span {
                        font-size: $navbar-font-size;
                        color: $gray-800;
                        font-weight: $font-weight-bold;
                        font-family: $headings-font-family;
                        white-space: nowrap;
                        display: inline-flex;
                        align-items: center;

                    }
                }
                &.dropdown {
                    .menu-link-normal {
                        span {
                            &::after {
                                @include svg-icon($font-size-sm, $font-size-sm, $icon-caret-down, $primary);
                            }
                        }
                    }
                }

                &:hover {
                    .menu-link-normal {
                        background-color: $gray-100;
                    }
                }

                .menu-link-normal {
                    display: block;
                }

                &:last-child {
                    display: block;

                    a {
                        color: $red;
                    }
                }

                &.dropdown {
                    a {
                        font-size: $font-size-sm;

                        span {
                            white-space: nowrap;

                            &::after {
                                display: inline-block;
                            }
                        }
                    }
                }

                .dropdown-menu {
                    [class*='col-'] {
                        .widget {
                            &+.widget {
                                a {
                                    &::before {
                                        display: none;
                                    }
                                }
                            }
                        }

                        p {
                            margin-bottom: map-get($spacers, 2);

                            a {
                                padding-bottom: map-get($spacers, 2);
                                padding-left: 0;
                                color: $primary;

                            }

                            b span {
                                color: $gray-500;
                            }

                            span {
                                text-decoration-color: $gray-500;

                                strong {
                                    color: $gray-500;
                                }
                            }
                        }
                    }

                    @include media-breakpoint-down($main-breakpoint) {
                        padding: map-get($spacers, 2) map-get($spacers, 1);
                    }
                }
            }
        }
        .offcanvas-heading {
            background-color: $offcanvas-heading-bg;
        }

        .navbar-nav {
            .menu-item {
                &:hover {
                    background-color: $gray-100;
                }

                &.dropdown {
                    b {
                        &.dropdown-toggle {
                            background-color: $primary;
                            top: 0.35rem
                        }
                    }
                }
            }
        }
    }
}
