.catalog-product-view {

    h1 {
        .base {
            font-family: $headings-font-family;
            @include font-size($h3-font-size);
        }
    }

    .product-info-main {
        margin-bottom: 2rem;
        background-color: lighten($gray-100, 2.75);

        .product-attribute {
            color: $gray-500;
            font-size: ( $font-size-sm * 1.1 );
        }

        .mage-error {
            color: $red;
            font-size: $font-size-sm;
        }

        .product-size {
            color: $gray-500;
            font-size: $font-size-sm;
            font-weight: 300;

            .icon {
                @include svg-icon(1rem, 1rem, $icon-ruler-horizontal, $primary);
            }
        }

        .product-info-price {
            .price-box {
                .price {
                    color: $primary;
                    font-family: $headings-font-family;
                    font-weight: $font-weight-bold;
                }
                [data-price-type='finalPrice'] {
                    .price {
                        @include font-size($h2-font-size);
                    }
                }
                [id*='old-price-'] {
                    .price {
                        color: $gray-300;
                    }
                }
            }
        }

        .product-options-wrapper {
            .swatch-attribute-label {
                color: $gray-900;
                font-family: $headings-font-family;
            }
            .swatch-option {
                height: 1.5rem;
                min-width: 1.875rem;
                padding: map-get($spacers, 1) map-get($spacers, 2);

                &.text {
                    color: $gray-400;
                    font-size: $font-size-sm;
                    font-weight: $headings-font-weight;
                }
            }
        }

        .product-options-bottom {
            margin-right: 0;
            border: none;
            padding: 0;
            background-color: transparent;

            .box-tocart {
                .form-inline {
                    display: flex;
                    align-items: stretch;
                }

                .spinbox {
                    .input-group {
                        background-color: $white;
                        border: none;
                        box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.1);
                        -moz-box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.1);
                        -webkit-box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.1);
                    }

                    .input-group-btn {
                        background-color: $white;
                    }
                }

                .tocart {
                    padding-top: 0;
                    padding-bottom: 0;
                    height: 100% !important;
                    font-weight: $font-weight-base;

                    @include media-breakpoint-down($main-breakpoint) {
                        padding: map-get($spacers, 2) 0;
                    }

                    &.btn-cta,
                    &:active {
                        background-color: $green;
                        border-color: $green;
                    }

                    &:focus,
                    &:hover {
                        background-color: darken($green, 10);
                        border-color: darken($green, 10);
                    }
                }
            }

        }

        .single-product-usp {
            font-size: $font-size-sm;
            font-weight: $font-weight-bold;

            .value {
                color: $green;
            }
        }

        .product-stock {
            margin-bottom: map-get($spacers, 2);

            .stock {
                padding: map-get($spacers, 1) map-get($spacers, 2);
                display: inline-flex;
                border: $border-width solid;
                border-radius: $border-radius-sm;
                font-size: ($font-size-base * .75);

                &.available {
                    border-color: $green;

                    span {
                        color: $green;
                        display: flex;
                        align-items: center;

                        .icon-check {
                            @include svg-icon(0.75rem, 0.75rem, $icon-check, $green);
                            margin-right: 0.25rem;
                        }
                    }
                }
            }
        }

        .configurator, .product-configurator {
            background-color: $white;
            border-color: $green;

            .title {
                color: $gray-900;
            }

            .checkbox {
                span {
                    font-size: $font-size-sm;
                }
            }

            .configurator__step {
                .configurator-step-title {
                    color: $gray-900;
                    font-size: $font-size-base;
                    font-weight: $font-weight-bold;
                }
            }

            .form-control {
                background-color: lighten($gray-100 ,  2.75);
                border-color: lighten($gray-100 ,  2.75);
                font-size: $font-size-sm;
            }

            .configurator__field {
                .radio {
                    label {
                        color: $gray-500;
                        font-size: $font-size-sm;
                    }
                }

                &#stepTwo {
                    .radio {
                        label {
                            font-size: $font-size-lg;
                        }
                    }
                }
            }

            label {
                span {
                    color: $primary;
                }

            }

            p {
                small {
                    color: $gray-500;
                }
            }

        }

        .product-usp {
            .block-title {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &.collapsed {
                    &::after {
                        @include svg-icon(1rem, 1rem, $icon-plus, $primary);
                    }
                }

                &::after {
                    @include svg-icon(1rem, 1rem, $icon-minus, $primary);
                }

            }
            .product-usp-item {
                .product-usp-item-title {
                    span {
                        &:first-of-type {
                            @include svg-icon($font-size-sm, $font-size-sm, $icon-check, $green);
                            vertical-align: middle;
                        }

                        color: $gray-500;
                        font-weight: $font-weight-light;
                        font-size: $font-size-sm;
                    }
                }
            }
        }
    }
}

$labelColors: (
    discount: $primary,
    red: $red,
    purple: $purple,
    orange: $orange,
    green: #00d177,
    blue: $blue,
    black: $black,
    yellow: $yellow
);

$iconClasses: (
    star: $icon-star-sharp,
    gift: $icon-gift,
    heart: $icon-heart,
    award: $icon-award,
    thumbs-up: $icon-thumbs-up
);
$iconSize: 2.25rem;

@each $bgClass, $bgColor in $labelColors {
    .product-label {
        &.#{$bgClass} {
            background-color: $bgColor;
        }
    }
}

@each $iconClass, $iconValue in $iconClasses {
    .product-label {
        .icon {
            &-#{$iconClass} {
                @include svg-icon($iconSize, $iconSize, $iconValue, $white);
            }
        }
    }
}

.catalog-product-view {
    .product-media {
        position: relative;

        .product-label {
            position: absolute;
            z-index: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 5.5rem;
            height: 5.5rem;
            right: 5%;
            border-radius: $rounded-pill;

            .product-label-text {
                color: $white;
                font-weight: $headings-font-weight;
            }

            &.sale {
                .product-label-combined {
                    .amount {
                        color: $white;
                        font-size: 1.875rem;
                        font-weight: $font-weight-bold;
                    }
                }
            }
        }
    }
}

.catalog-product-view {
    .page-title-wrapper {
        .product-reviews-summary {
            .action {
                &.add {
                    text-decoration: none;
                    color: $gray-400;
                    font-size: $font-size-sm;
                }
            }
        }
    }

    .product-details-wrapper {
        background-color: lighten($gray-100, 2.75);

        .description {
            .title {
                color: $primary;
            }

            h2,
            h3 {
                font-size: $font-size-lg;
            }

            .title,
            h2,
            h3 {
                font-weight: $headings-font-weight;
            }

            h2,
            h3,
            p {
                color: $gray-500;
            }

            p,
            table {
                font-size: $font-size-sm;
            }
        }
    }

    .additional-attributes {
        .label-collapse {
            color: $primary;
            font-weight: $headings-font-weight;
            display: flex;
            align-items: center;

            &:after {
                @include svg-icon(1.25rem, 1.25rem, $icon-minus-square-solid, $gray-900);
                cursor: pointer;
            }

            &.collapsed {
                &:after {
                    @include svg-icon(1.25rem, 1.25rem, $icon-plus-square-solid, $gray-900);
                }
            }

        }

        #attributes-details {
            .item {
                &+.item {
                    border-top: $border-width solid $gray-100;
                }

                dt,
                dd {
                    color: $gray-500;
                    font-size: $font-size-sm;
                }
            }
        }
    }

    .review-list,
    .product-related-wrapper {

        .block-title,
        .title {
            color: $gray-900;
            @include font-size($h4-font-size);
            font-weight: $headings-font-weight;
        }

        .review-ratings {
            .rating-label {
                color: $gray-500;
                font-size: $font-size-sm;
            }
        }

        .review-details {
            .review-author {
                .review-details-value {
                    color: $gray-500;
                    font-weight: $font-weight-bold;
                }
            }
        }
        .review-items {
            .review-item {
                .review-title {
                    font-size: $font-size-base;
                    color: $gray-500;
                }

                .review-content {
                    font-size: $font-size-sm;
                    color: $gray-500;
                }
            }
        }
    }

    .product-related-wrapper {
        .product-items {
            .product-item {
                &:hover {
                    box-shadow: 0 20px 20px 0 rgba(116, 116, 116, 0.3);
                    -moz-box-shadow: 0 20px 20px 0 rgba(116, 116, 116, 0.3);
                    -webkit-box-shadow: 0 20px 20px 0 rgba(116, 116, 116, 0.3);
                }

                .product-item-info {
                    border: none;
                }
            }
        }
    }
}

.fotorama, .fotorama--fullscreen {
    margin-bottom: 2rem;

    .fotorama__caption {
        display: none;
    }

    .fotorama__thumb {
        height: 90%;
        background: transparent;
    }
    .fotorama__thumb-border {
        border: none;
        background-image: none;

        &::after {
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            content: '';
            display: block;
            width: 44%;
            height: 4px;
            background-color: $primary;
            bottom: 0;
        }
    }
}
.product-media  {
    .fotorama__arr--prev, .fotorama__arr--next {
        &::before {
            border-radius: $border-radius-lg ;
            background-color: $white;
        }
    }
    .fotorama__arr--prev {
        &::before {
            @include svg-icon(1.25rem, 1.25rem, $icon-angle-left, $primary);
        }
    }
    .fotorama__arr--next {
        &::before {
            @include svg-icon(1.25rem, 1.25rem, $icon-angle-right, $primary);
        }
    }
    .fotorama__active .fotorama__dot {
        background-color: $primary;
        border-color: $primary;
    }
    .product-label {
        top: 0;
    }
}
