.product-info-main {
    .bundle-actions {
        margin-bottom: map-get($spacers, 2);
    }
}
.bundle-options-wrapper {

}
.bundle-options-container {
    .product-add-form {
        display: none;
        margin-bottom: map-get($spacers, 3);

        form {
            @include make-row();
        }
    }

    .bundle-options-wrapper {
        @include make-col-ready();
        @include make-col(7);

        button.back {
            margin-bottom: map-get($spacers, 3);
            @include button-outline-variant($gray-600, $gray-600);

            &:hover {
                color: $white;
            }
        }
        .option {
            margin-bottom: map-get($spacers, 3);

            > label.label {
                font-weight: $font-weight-bold;
            }
        }
        .qty-holder {
            .qty {
                max-width: $spinbox-width;
                text-align: center;
            }
        }
    }
    .block-bundle-summary {
        @include make-col-ready();
        @include make-col(5);

        .product-image-container {
            display: none;
        }
        .box-tocart {
            margin-bottom: map-get($spacers, 3);
        }
        .price-box {
            @include font-size($h3-font-size);
        }
        ul {
            padding-left: 0;
            list-style: none;

            li {
                + li {
                    margin-top:  map-get($spacers, 2);
                }
            }
        }
    }
}
