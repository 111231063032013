.catalogsearch-result-index {
    h1.page-title  {
        font-family: $headings-font-family;
        color: $gray-900;
    }
}

.panel-heading.filter-title {
    display: none;
}

.filter-options-item {
    .amshopby-slider-container.-default {
        .am-slider {
            height: 0.25rem;
            .ui-slider-range {
                height: 0.25rem;
                background-color: $gray-800;
                position: absolute;
            }
            .ui-slider-handle {
                background-color: $pink !important;
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
                bottom: -200%;
                width: 1.125rem;
                height: 1.125rem;
                &:hover,
                &:focus,
                &:active {
                    background-color: $secondary !important;
                }
            }
        }
        
        .amshopby-slider-display {
            margin: map-get($spacers, 2);
        }
    }
}

.filter.block {
    a:hover {
        text-decoration: none;
    }

    .panel-heading {
        .block-subtitle {

        }
    }

    .filter-current {
        .items {
            .item {     
                position: relative;

                .filter-label {
                    color: $orange;
                }

                .filter-value {
                    margin-left: map-get($spacers, 1);
                }

                .action {
                    position: absolute;
                    right: 0;

                    &.remove {
                        &::after {
                            @include svg-icon(1rem, 1rem, $icon-close, $secondary);
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
    }

    .panel {
        margin-bottom: 1.25rem;
    }

    .filter-options-title, .panel-heading {
        &.block-subtitle, .block-subtitle {
            @include font-size($h2-font-size);
            font-family: $headings-font-family;
            font-weight: $font-weight-light;
            color: $pink;
        }
        
            margin-top: 1.25rem;
            
            &.trigger {
                position: relative;
                .filter-options-title {
                    width: 100%;
                }
                &:after {
                    @include svg-icon(0.85rem, 0.85rem, $icon-minus-square, $gray-200);
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translate(0%, -35%);
                }

                &.collapsed {
                    &:after {
                        @include svg-icon(0.85rem, 0.85rem, $icon-plus-square, $gray-200);
                    }
                }
            }
    }
}

.filter-options-item {
    margin-bottom: map-get($spacers, 2);
    &+.filter-options-item {
        border-top: 1px dashed $gray-200;
    }

    &.categorie {
        .filter-options-content {
            .items {
                .item {
                    [class*='am-filter-item-'] {
                        justify-content: flex-start;
                        font-weight: $font-weight-semibold;
                        line-height: 1.5;
                    }
                }
            }
            .am-show-more {
                padding: 0;
                color: lighten($secondary, 40);

                &:focus {
                    box-shadow: none;
                    outline: 5px auto -webkit-focus-ring-color;
                }
                .hide_count {
                    margin-right: map-get($spacers, 1);
                }
            }
        }
    }

    .filter-options-title {
        margin-bottom: map-get($spacers, 1);
        @include font-size($h2-font-size);
        font-family: $headings-font-family;
        font-weight: $font-weight-normal;
        color: $pink;
        text-transform: capitalize;
    }

    &.price {
        .filter-options-title {
            margin-bottom: map-get($spacers, 4);
        }
    }

    .filter-options-content {
        margin-bottom: 1.25rem;

        .am-shopby-form {
            margin: 0 !important;
        }
        .items {
            padding-inline-start: 0;

            &:not(.items-children):not(.am-category-view) {
                margin: 0 !important;
            }

            .item {
                list-style: none;
                display: flex;
                align-items: center;
                margin-bottom: 0.25rem;

                [class*='am-filter-item-'] {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;

                    .filter-label-container {
                        display: flex;
                        align-items: center;

                    }
                    .count {
                        margin-left: map-get($spacers, 1);
                        color: $secondary;
                        font-size: $font-size-base;
                        &:before {
                            content: '(';
                        }
                        &:after {
                            content: ')';
                        }
                    }
                }
                .items-children {
                    display: none;
                }
                .am-collapse-icon {
                    display: none;

                    &~[class*='am-filter-item-'] {
                        padding-left: 0;
                        
                    }
                }
            }
        }

        .badjas_colorgroup {
            display: flex;
            flex-direction: column;
            row-gap: map-get($spacers, 1);

            .am-swatch-link {
                margin: 0;
                column-gap: map-get($spacers, 1);

                .swatch-option {
                    margin: 0;
                    padding: 0;
                    width: 1.75rem;
                    min-width: 1.75rem ;
                    height: 1.25rem;
                    border-radius: 0;

                    &+input[type="radio"] {
                        display: none !important;
                    }
                }
            }
        }

        .size_filter {
            display: flex;
            flex-wrap: wrap;

            .swatch-option {
                font-size: $font-size-sm;

                &.text {
                    background-color: lighten($gray-100, 0.75);
                    border-color: lighten($gray-100, 0.75);
                    &:hover {
                        border-color: $light-blue;
                    }
                }
            }
        }
    }
}

.amshopby-items {
    .amshopby-filter-name {
        margin-right: 1rem;
    }
}

.am-swatch-link {
    display: flex;
    align-items: center;
}
.iHidden {
    display: none;
}