.navbar {
    background-color: #edfaff;
    .navbar-toggle.collapsed {
        display: none;
    }


    @include media-breakpoint-down($main-breakpoint) {
        button.navbar-toggler {
            display: none;
        }
        .navbar-toggle.collapsed {
            display: block;
        }
        .navbar-nav {
            padding: 0;
            font-size: 1.4rem;

            li {
                padding: 0.8rem 1rem;
            }

            .menu-bar {
                background: $secondary;
                color: $white;
            }
        }
        .icon-bar {
            width: 28px;
            height: 2px;
            display: block;
            background-color: #888;
            margin-top: 3px;
        }
        .menu-text {
            font-size: 0.7rem;
        }
        .navbar-minicart .icon {
            width: 2rem;
        }
        .navbar-header{
            .navbar-header-left, .navbar-header-right {
                width: auto;
            }

            .navbar-header-center {
                flex: 1
            }
        }
        .navbar-header-right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        .icon.icon-search::before {
            @include svg-icon(1rem, 2rem,  $icon-search-navbar, $secondary);
        }
        .navbar-search-toggle {
            margin-right: 15px;
        }
        div#navbar-offcanvas {
            max-width: 18rem;
            z-index: $zindex-offcanvas;
        }
    }
}
.navbar {
    background-image: url(../images/pattern-waves.png);

    @include media-breakpoint-down($main-breakpoint) {
        padding: 0 1rem;
    }
    .menu-item a {
        padding: 0.85rem 1rem;
        font-weight: $font-weight-normal;
        color: $secondary;
        font-size: 1rem;
        @include media-breakpoint-down($main-breakpoint) {
            padding: map-get($spacers, 1) 1.25rem;
            display: block;
        }
    }
    #navbar-offcanvas {
        margin-left: 11.4rem;
    }
}
header {
    margin-bottom: 0;
}
.dropdown{
    .menu-link-normal span::after {
        @include svg-icon(1.2rem, 1.2rem, $icon-angle-down, $pink);
        background-position: top;
        vertical-align: middle;
    }

    @include media-breakpoint-down($main-breakpoint) {
        position: relative;
    }
}
.fa.fa-star,
.icon.icon-star {
    margin-left: -0.15rem;
    @include svg-icon(1.2rem, 1.2rem, $icon-star, $pink);
    vertical-align: text-top;
}
.dropdown-toggle::after {
    @include media-breakpoint-up($main-breakpoint) {
        display: none;
    }
}
b.dropdown-toggle {
    position: absolute;
    right: map-get($spacers, 3);
    top: map-get($spacers, 2);
}
.offcanvas-heading {
    background: $secondary;

    .title {
        font-family: $font-family-base;
        font-weight: $font-weight-base;
    }

    .close {
        opacity: 1;
        .icon {
            @include svg-icon(1rem, 1rem, $icon-times, $white);
        }
    }
}

.menu-item {
    @include media-breakpoint-down($main-breakpoint) {
        padding: 0.5rem 0;

        .menu-link-normal {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

}
