.block-search {
    input {
        &#search_search_mini_form {
            background-color: lighten($gray-100, 2.75);
            border-color: lighten($gray-100, 2.75);
            color: $gray-300;
            font-size: $font-size-sm;

            &::placeholder {
                color: $gray-300;
            }
        }
    }
}