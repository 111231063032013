.block {
    &.filter {
        margin-bottom: map-get($spacers, 5);

        .block-title {
            display: none;
        }
        .block-subtitle {
            display: block;
            @include heading($font-size-lg);
        }
        .filter-current {
            margin-bottom: map-get($spacers, 2);
            
            .items {
                padding: 0;
                margin-bottom: 0;
                list-style: none;

                .item {
                    .filter-label {
                        font-weight: $font-weight-bold;
                    }
                    .remove {
                        &:after {
                            @include svg-icon(1rem, 1rem, $icon-close, $body-color);
                        }
                        span {
                            display: none;
                        }
                    }
                }
            }
        }
        .filter-actions {
            margin-bottom: map-get($spacers, 3);
        }
        .filter-options, .options {
            .items {
                padding: 0;
                list-style: none;

                .item {
                    .count {
                        color: $gray-400;
                        font-size: $font-size-sm;

                        &:before {
                            content: '(';
                        }
                        &:after {
                            content: ')';
                        }
                        .filter-count-label {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
.btn-layered {
    display: none;
    
    @include media-breakpoint-down(sm) {
        display: inline-block;
    }
}
