.products-grid {
    .product-items,
    .product-col {
        .product-item-configuration {
            border: 0.5rem solid transparent;
            bottom: -4.75rem;
        }

        &:hover {
            .product-item-configuration {
                right: -1rem;
                left: -1rem;
                border: 0.5rem solid transparent;
                box-shadow: 0 25px 20px -5px rgba(116, 116, 116, 0.3);
                -moz-box-shadow: 0 25px 20px -5px rgba(116, 116, 116, 0.3);
                -webkit-box-shadow: 0 25px 20px -5px rgba(116, 116, 116, 0.3);

                [class*='swatch-opt-'] {
                    display: block !important;
                }
            }
        }

        .product-item {
            .product-item-info {
                border: none;

                .product-item-details {
                    .price-box {
                        [id*='old-price-'] {
                            .price {
                                color: $gray-300;
                            }
                        }
                    }

                    .stock {
                        padding: 0 map-get($spacers, 1);
                        font-size: $font-size-sm;
                        font-weight: $font-weight-bold;
                        text-transform: uppercase;

                        &.available {
                            color: $green;

                            span {
                                &::before {
                                    margin-right: map-get($spacers, 1);
                                    @include svg-icon(1rem, 1rem, $icon-check, $green);
                                    vertical-align: middle;
                                }
                            }
                        }
                    }

                    [class*='swatch-opt-'] {
                        display: none;
                        margin-top: map-get($spacers, 3);
                    }

                    .swatch-attribute-options {
                        .swatch-option {

                            &.text,
                            &.color {
                                min-height: 1.5rem;
                                min-width: 2rem;
                                font-size: 0.7rem;
                                color: $gray-300;
                                border-color: $gray-100;
                            }
                        }
                    }
                }
            }
        }
    }

    .product-item-photo {
        .product-item-attribute {
            position: absolute;
            right: -1rem;
            top: -0.5rem;

            @include media-breakpoint-down($main-breakpoint) {
                right: map-get($spacers, 2);
            }

            @include media-breakpoint-down(md) {
                right: 0;
            }

            .product-label {
                flex-direction: column;
                justify-content: center;
                row-gap: 0.25rem;
                border-radius: $rounded-pill;
                width: 4.5rem;
                height: 4.5rem;
                font-size: $font-size-sm;
                font-weight: $font-weight-bold;

                @include media-breakpoint-down($main-breakpoint) {
                    width: 3.25rem;
                    height: 3.25rem;

                    .product-label-text {
                        font-size: 0.75rem;
                    }

                    &.discount {
                        .product-label-text {
                            font-size: 1.5rem;
                        }
                    }
                }

                .product-label-text,
                .icon {
                    padding: 0 $spacer;
                }

                &::before,
                &::after,
                .arrow-corner {
                    display: none;
                }

                &.discount {
                    .product-label-text {
                        @include font-size($h2-font-size);
                    }
                }
            }
        }
    }

    $labelColors: (sale: $primary,
        red: $red,
        purple: $purple,
        orange: $orange,
        green: #00d177,
        blue: $blue,
        black: $black,
        yellow: $yellow);

    $iconClasses: (star: $icon-star-sharp,
        gift: $icon-gift,
        heart: $icon-heart,
        award: $icon-award,
        thumbs-up: $icon-thumbs-up);

    $iconSize: 2rem;
    $iconSizeSm: 1.25rem;

    @each $bgClass,
    $bgColor in $labelColors {
        .product-item-photo {
            .product-item-attribute {
                .product-label {
                    &.#{$bgClass} {
                        background-color: $bgColor;
                    }
                }
            }
        }
    }

    @each $iconClass,
    $iconValue in $iconClasses {
        .product-label {
            .icon {
                &-#{$iconClass} {
                    @include svg-icon($iconSize, $iconSize, $iconValue, $white);
                }
            }

            @include media-breakpoint-down($main-breakpoint) {
                .icon {
                    &-#{$iconClass} {
                        @include svg-icon($iconSizeSm, $iconSizeSm, $iconValue, $white);
                    }
                }
            }
        }
    }

    .product-col,
    .widget-product-item.product-item {
        position: relative;
        border: 0.5rem solid transparent;

        &:hover,
        &:focus {
            border: 0.5rem solid transparent;
            box-shadow: 0 0px 20px 0 rgba(116, 116, 116, 0.3);
            -moz-box-shadow: 0 0px 20px 0 rgba(116, 116, 116, 0.3);
            -webkit-box-shadow: 0 0px 20px 0 rgba(116, 116, 116, 0.3);
        }

        .product-item-info {
            .product-image-wrapper {
                img {
                    border: none;
                }
            }

            .product-item-details {
                align-items: flex-start;
                text-align: left;

                .product-item-name {
                    .product-item-link {
                        text-align: left;
                        color: $gray-500;
                        font-size: $font-size-base * 1.125;
                        font-weight: $headings-font-weight;
                    }
                }

                .price-box,
                .product-reviews-summary {
                    padding: 0 map-get($spacers, 2);
                }

                .price-box {
                    [data-price-type="oldPrice"] {
                        .price {
                            color: $gray-300;
                            font-weight: $font-weight-light;
                        }
                    }

                    .price {
                        font-weight: $font-weight-bold;
                        font-family: $headings-font-family;
                        color: $primary;
                    }
                }

                .stock-status {
                    .stock {
                        font-weight: $font-weight-bold;
                        font-size: $font-size-sm;
                        text-transform: uppercase;

                        &.available {
                            color: $green;
                        }
                    }
                }
            }
        }
    }
}

.catalog-category-view {
    .category-description {

        p,
        a {
            font-size: $font-size-sm;
        }

        p {
            color: $gray-500;
        }
    }

    .container-main {
        margin-bottom: map-get($spacers, 4);
        padding-bottom: map-get($spacers, 4);
    }

    .category-info-wrapper {
        padding-top: map-get($spacers, 4);
        border-top: $border-width solid $gray-200;

        h2 {
            color: $gray-900;
            font-weight: $headings-font-weight;
        }

        p {
            font-size: $font-size-sm;
            color: $gray-500;
        }

        table {
            thead {
                color: $gray-500;
            }

            tbody {
                tr {
                    td {
                        a {
                            font-size: $font-size-sm;
                        }
                    }
                }
            }
        }
    }

    .read-more {
        &::after {
            @include svg-icon($font-size-sm, $font-size-sm, $icon-angle-right, $primary);
            vertical-align: middle;
        }

        &:hover {
            &::after {
                @include svg-icon($font-size-sm, $font-size-sm, $icon-angle-right, $link-hover-color);
            }
        }
    }

    .toolbar-products {

        .toolbar-limiter,
        .toolbar-sorter {
            .input-group {
                .input-group-text {
                    font-size: $font-size-sm;
                }

                .dropdown {
                    .dropdown-toggle {
                        background-color: lighten($gray-100, 2.75);
                        border: 0;
                        font-size: $font-size-sm;
                    }

                    .dropdown-menu {
                        .dropdown-item {
                            &.active {
                                background-color: $primary;
                            }
                        }
                    }
                }
            }
        }
    }

    .sidebar {
        #layeredNav {
            .filter-content {
                .panel-default {
                    .panel-heading {
                        .block-subtitle {
                            padding-bottom: map-get($spacers, 2);
                            border-bottom: 1px solid $gray-100;
                            color: $gray-500;
                            font-size: $font-size-base;
                            font-weight: $font-weight-bold
                        }
                    }

                    .filter-current {
                        .items {
                            font-size: $font-size-sm;

                            .filter-label {
                                color: $gray-500
                            }
                        }
                    }
                }
            }
        }

        .block-content {
            .filter-options-title {
                @include font-size($h5-font-size);
                color: $gray-500;
                font-weight: $font-weight-bold;
            }

            .filter-options-content {
                .item {
                    a {

                        span,
                        .count {
                            color: $gray-500;
                            font-size: $font-size-sm;

                            .filter-count-label {
                                display: none;
                            }
                        }
                    }
                }

                .swatch-option {
                    &.text {
                        font-size: ($font-size-sm * 0.85);
                    }
                }

                span {
                    &.swatch-label {
                        font-size: $font-size-sm;
                    }
                }
            }

            .am-show-more {
                color: $primary;
            }

            .amshopby-slider-display {
                color: $gray-500;
                font-size: $font-size-sm;
            }
        }
    }
}

$labelColors: (sale: $primary,
    red: $red,
    purple: $purple,
    orange: $orange,
    green: $green,
    blue: $blue,
    black: $black,
    yellow: $yellow
    );

$iconClasses: (
    star: $icon-star-sharp,
    gift: $icon-gift,
    heart: $icon-heart,
    award: $icon-award,
    thumbs-up: $icon-thumbs-up
    );
$iconSize: 2.25rem;

@each $bgClass,
$bgColor in $labelColors {
    .product-label {
        &.#{$bgClass} {
            background-color: $bgColor;
        }
    }
}

@each $iconClass,
$iconValue in $iconClasses {
    .product-label {
        .icon {
            &-#{$iconClass} {
                @include svg-icon($iconSize, $iconSize, $iconValue, $white);
            }
        }
    }
}

.catalogsearch-result-index,
.catalog-category-view {
    .product-item-photo {
        position: relative;

        .product-label {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 4.5rem;
            height: 4.5rem;
            right: -10%;
            border-radius: $rounded-pill;

            .product-label-text {
                color: $white;
                font-size: $font-size-sm;
                font-weight: $headings-font-weight;
                text-align: center;

                @include media-breakpoint-down($main-breakpoint) {
                    font-size: 0.75rem;
                }
            }

            &.sale {
                .product-label-combined {
                    .amount {
                        color: $white;
                        font-size: 1.5rem;
                        font-weight: $font-weight-bold;
                    }
                }
            }

            @include media-breakpoint-down($main-breakpoint) {
                width: 3.25rem;
                height: 3.25rem;

                &.sale {
                    .product-label-combined {
                        .amount { 
                            font-size: $font-size-base;
                        }
                    }
                }
            }
        }
    }
}

.filter-options-item {
    .amshopby-slider-container.-default {
        .am-slider {
            height: 0.25rem;

            .ui-slider-range {
                height: 0.25rem;
                background-color: $gray-500;
                position: absolute;
            }

            .ui-slider-handle {
                display: block;
                position: absolute;
                background-color: $primary !important;
                height: 1.125rem;
                width: 1.125rem;
                -webkit-border-radius: 10px;
                border-radius: 10px;
            }
        }
    }
}
