$svg-plus: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"/></svg>');
$svg-minus: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M368 224H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h352c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"/></svg>');


.box-tocart {
    .action {
        &.tocart {
            @include button-variant($btn-addtocart, $btn-addtocart);
        }
    }
}
.spinbox-down {
    display: flex;
    align-items: center;

    .icon {
        @include svg-icon(1rem, 1rem, $svg-minus);
    }
}
.spinbox-up {
    display: flex;
    align-items: center;
    
    .icon {
        @include svg-icon(1rem, 1rem, $svg-plus);
    }
}
.product-addto-links {
    .action {
        @extend .btn;

        &.towishlist,
        &.tocompare {
            &:before {
                margin-right: map-get($spacers, 2);
            }
        }
        &.towishlist {
            @include button-variant($btn-wishlist, $btn-wishlist);

            &:before {
                content: ' ';
                @include svg-icon(1rem, 1rem, $icon-wishlist, $body-color);
            }
        }
        &.tocompare {
            @include button-variant($btn-compare, $btn-compare);

            &:before {
                content: ' ';
                @include svg-icon(1rem, 1rem, $icon-compare, $body-color);
            }
        }
    }
}
.attribute-collapse {
    .label-collapse {
        cursor: pointer;
    }
}
