.cms-home {

    h1,
    h2,
    .block-title span,
    h1 .base {
        color: $gray-900;
        font-weight: $headings-font-weight;
    }

    h2 {
        font-size: $h2-font-size;
    }

    .block-title span {
        font-size: $h2-font-size;
    }

    nav.navbar {
        margin-bottom: map-get($spacers, 3);
    }

    .home-block {
        &.top-list {
            box-shadow: none;

            .title {
                color: $gray-900;
                @include font-size($h2-font-size);
                font-weight: $headings-font-weight;
                white-space: nowrap;

                @include media-breakpoint-down($main-breakpoint) {
                    white-space: normal;
                }
            }

            .subtitle {
                color: $gray-500;
                font-size: 1.125rem;
                font-weight: $font-weight-light;
            }

            .products-list {
                .product-item {
                    padding: map-get($spacers, 2) 0;

                    &+.product-item {
                        border-top: ($border-width * 4) dotted lighten($gray-200, 4.75);
                    }

                    &:last-child {
                        border-bottom: none;
                        padding-bottom: 0;
                    }
                    .product-item-details {
                        &:hover {
                            text-decoration: none;
                        }
                        .product-name {
                            color: $gray-900;
                        }

                        .price-box {
                            .price {
                                color: $primary;
                                font-size: $font-size-sm;
                            }
                        }
                    }

                    .product-name,
                    .price-box .price {
                        font-weight: $font-weight-bold;
                        font-family: $headings-font-family;
                    }
                }
                .product-number, .product-item-photo {
                    @include make-col(3);
                }
                .product-item-details {
                    @include make-col(6);
                }
                .product-number {
                    &::after {
                        background-image: none;
                        width: 3rem;
                        height: 3rem;
                        border-radius: $rounded-pill;
                        background-color: $primary;
                    }

                    .number {
                        top: 42.5%;
                        left: 30%;
                    }
                }
            }
        }
    }

    .columns {
        p {
            color: $gray-500;
            font-size: $font-size-sm;
        }
    }

    .home-block-big {
        background-color: $primary;
        color: $white;

        .left,
        .right {
            padding: 0;
        }

        .left {
            .content {
                padding: map-get($spacers, 4) map-get($spacers, 5);

                .title {
                    @include font-size($h1-font-size);
                    font-weight: $headings-font-weight;
                }

                .sub-title {
                    @include font-size($h3-font-size);
                }
            }
        }

        .right {
            .image {
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    border-top: 1.429em solid transparent;
                    border-left: 1.429em solid $primary;
                    border-bottom: 1.429em solid transparent;
                    z-index: 100;
                }

                @include media-breakpoint-down($main-breakpoint) {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    .column {
        ul {
            li {
                &::marker {
                    color: $gray-500;
                }
                b {
                    color: $gray-500;
                    font-size: $font-size-sm;
                }
            }
        }
    }
    .home-products {
        .block-title {
            &.block-title-mobile,
            &.block-title-desktop {
                display: none;
            }

            @include media-breakpoint-down($main-breakpoint) {
                &.block-title-desktop {
                }
                &.block-title-mobile {
                    display: none;
                }
            }
        }
    }
}

.home-block-brands {
    .content {
        .title {
            @include font-size($h2-font-size);
            font-family: $headings-font-family;
            font-weight: $headings-font-weight;
            color: $gray-900;
        }

        .description {
            font-size: $font-size-lg;
            font-weight: $font-weight-base;
        }
    }

    .brands {
        padding: 0;
        padding-bottom: map-get($spacers, 5);
        margin: map-get($spacers, 4) 0;
        border-bottom: 1px solid darken($gray-100, 2.25);

        @include media-breakpoint-down($main-breakpoint) {
            padding-bottom: $spacer;
            margin-bottom: 0;
        }

        .brand-logo {
            display: inline-block;
            margin-right: map-get($spacers, 4);

            img {
                -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
                transition: all;
                transition-duration: 0.25s;
                transition-timing-function: ease;
                opacity: 0.4;

                @include media-breakpoint-down(sm) {
                    margin: map-get($spacers, 2);
                }

                &:hover {
                    -webkit-filter: grayscale(0%);
                    filter: grayscale(0%);
                    transition: all;
                    transition-duration: 0.25s;
                    opacity: 1;
                    transition-timing-function: ease;
                }
            }
        }

        .slick-list {
            max-width: 95%;
            margin: 0 auto;
        }

        .slick-arrow {
            border: none;
            background: #fff;
            color: $primary;
            width: 2rem;
            height: 2rem;
            font-size: $font-size-base;
            padding-left: 0.5em;
            padding-right: 0.5em;
            // .box-shadow(0 2px 1px 0 rgba(0,0,0,0.1));

            @include media-breakpoint-down($main-breakpoint) {
                padding: 0 map-get($spacers, 2);
                margin: 0 map-get($spacers, 1);
                width: 1.5rem;
                height: 1.5rem;

            }
        }

        .prev-arrow {
            float: left;
            position: absolute;
            left: -15px;
            top: 15%;
            z-index: 9999;
        }

        .next-arrow {
            float: right;
            position: absolute;
            right: -15px;
            top: 15%;
            z-index: 9999;
        }
    }
}

.products-grid {
    .widget-product-item {
        &.product-item {
            .product-item-info {
                .product-item-details {
                    .stock {
                        padding: 0 map-get($spacers, 2);
                        font-size: $font-size-sm;
                        text-transform: uppercase;

                        &.available {
                            color: $green;
                            font-weight: $font-weight-bold;
                        }
                    }
                }
            }
        }
    }
}
