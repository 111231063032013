.block-search-mobile, .block-search {
    .input-group {
        position: relative;

        .form-control {
            border-radius: 1.25rem !important;
            border: ($border-width * 2) solid $input-border-color;

            &:focus, &:focus-visible {
                border-color: $secondary;
                outline: 0;
                box-shadow: none;
            }
        }
        .input-group-append {
            height: 2.125rem;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 99;
            border-width: 0;
            border-radius: 0;
            cursor: pointer;

            .search {
                border: none;
                background-color: transparent;
                &:disabled, &:hover {
                    background-color: transparent;

                }
                // &:disabled {
                //     @include svg-icon(1.5rem, 1.2rem, $icon-search, lighten($primary, 10));
                // }
            }
            .btn {
                &:focus {
                    box-shadow: none;
                }
            }
        }
    }
}

.block-search {
    margin-bottom: 0;
    min-width: 20rem;
    .form-group {
        margin-bottom: 0;
    }
    .actions {
        .search {
            &:before {
                content: ' ';
                @include svg-icon(1.5rem, 1.2rem, $icon-search, $primary);
                position: relative;
                top: 3px;

            }
            &:disabled {
                &::before {
                    @include svg-icon(1.5rem, 1.2rem, $icon-search, lighten($primary, 20));
                }
            }
            @include button-variant($btn-search, $btn-search);
        }
    }
    button.action.search span {
        display: none;
    }
    .input-group-append {
        .btn.btn-default {
            padding: 3px 0.75rem;

            @include media-breakpoint-down($main-breakpoint) {
                padding: 1px 0.375rem;
            }
        }
    }
    .icon.icon-search {
        @include svg-icon(1rem, 1rem, $icon-search, $primary);
        position: relative;
        top: 50%;
        transform: translate(0, 25%);

    }

    input#search_search_mini_form,
    input.form-control {
        height: 2.125rem;
        background-color: transparent;
    }
    button.action.search {
        color: $primary;
        background-color: transparent;
        opacity: unset;
        padding: 1px 10px;
    }
}

input {
    &[type="search"] {
        &::-webkit-search-decoration, 
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            -webkit-appearance:none;
        }
    }
}
#search_mobile_form {
    padding: map-get($spacers, 3);
    padding-bottom: 0;
    .form-control {
        height: 2.125rem;
        background-color: transparent;
    }
    .icon.icon-search {
        position: relative;
        top: 5px;
        @include svg-icon(1.5rem, 1.2rem, $icon-search, $primary);
    }
    .input-group-append {
        padding: 0px 10px;
    }
    .btn.btn-default {
        padding: 0;
        cursor: pointer;
    }
}
.form-control {
    font-weight: $font-weight-base;
    &::placeholder {
        color: $input-placeholder-color;
    }
}

.block-search, .block-search-mobile{
    position: relative;

    .active {
        position: relative;
    }
    #search_autocomplete, #search_autocomplete_mobile, #search_autocomplete-search_mini_form {
        z-index: 9999;
        position: absolute;
        top: 100%;
        background-color: $white;
        box-shadow: $box-shadow;

        ul[role="listbox"] {
            @extend .dropdown-menu;
            list-style: none;
            padding: map-get($spacers, 1) 0;
            display: block;
            width: 100%;
            box-shadow: $dropdown-box-shadow;
            li {
                    cursor: pointer;
                    padding: 0.185rem 1.25rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: $black;
                    text-decoration: none;

                &:hover, &:active {
                    background-color: $gray-100;
                } 
            }
        }
    }
}

.block-search-mobile + #search_mini_form.block-search {
    display: none;
}