.product-reviews-wrapper {
    padding: map-get($spacers, 5) 0;
    margin-bottom: map-get($spacers, 4);
}

.product-reviews-summary {
    .rating-summary {
        display: inline-flex;
        position: relative;

        .label {
            display: none;
        }
    }

    .reviews-actions {
        display: inline-flex;

        a {
            +a {
                margin-left: map-get($spacers, 3);
            }
        }
    }

    .rating-result {
        display: inline-block;

        >span {
            position: relative;
            display: block;
            overflow: hidden;
            z-index: 2;

            >span {
                display: none;
            }
        }
    }
}

.review-wrapper {
    &.offcanvas {
        max-width: 31.25rem;
        width: 100%;

        .review-add {
            padding: 2rem;

            h3,
            h4 {
                font-family: $font-family-sans-serif;
            }

            .btn-close {
                @include svg-icon(0.75rem, 0.75rem, $icon-close, $gray-800);
                float: right;
            }

            .review-legend {
                padding-bottom: map-get($spacers, 3);
                border-bottom: 1px solid $gray-100;

                span {
                    font-size: $h5-font-size;
                }

                strong {
                    font-size: $h3-font-size;

                }
            }

            .review-field-ratings {
                margin-top: map-get($spacers, 3);

                .review-field-rating {
                    display: flex;

                    .col-sm-3 {
                        padding-left: 0;
                    }
                }
            }

            label {
                font-weight: $font-weight-bold;
            }

            .form-control {
                box-shadow: none;

                &:focus {
                    outline: 5px auto -webkit-focus-ring-color;
                }
            }

            .review-control-vote {
                z-index: 0;

                .icon {
                    width: 1rem;
                    height: 1rem;
                }

                input[type="radio"] {
                    position: absolute;
                    padding: 0;
                    margin: -1px;
                    width: 1px;
                    height: 1px;
                    border: 0;
                    clip: rect(0, 0, 0, 0);
                    overflow: hidden;

                    &:checked {
                        .icon {
                            fill: $yellow;
                        }
                    }
                }

                label {
                    span {
                        border: 0;
                        clip: rect(0, 0, 0, 0);
                        height: 1px;
                        margin: -1px;
                        overflow: hidden;
                        padding: 0;
                        position: absolute;
                        width: 1px;
                    }
                }

                &:before {
                    display: block;
                    position: absolute;
                    height: 1rem;
                    color: $primary;
                    font-size: 1rem;
                    letter-spacing: 2px;
                    vertical-align: top;
                    z-index: 1;
                    speak: none;
                }

                input[type="radio"] {
                    position: absolute;
                    padding: 0;
                    margin: -1px;
                    width: 1px;
                    height: 1px;
                    border: 0;
                    clip: rect(0, 0, 0, 0);
                    overflow: hidden;

                    &:focus {
                        &+label {
                            &:before {
                                opacity: 1;
                            }
                        }
                    }

                    &:checked {
                        &+label {
                            &:before {
                                opacity: 1;
                            }
                        }
                    }
                }

                label {
                    display: block;
                    cursor: pointer;
                    position: absolute;


                    span {
                        border: 0;
                        clip: rect(0, 0, 0, 0);
                        height: 1px;
                        margin: -1px;
                        overflow: hidden;
                        padding: 0;
                        position: absolute;
                        width: 1px;
                    }

                    &:before {
                        color: $review-icon-color;
                        height: 1rem;
                        font-size: 1rem;
                        font-weight: 900;
                        letter-spacing: 2px;
                        vertical-align: top;
                        speak: none;
                        opacity: 0;
                    }

                    &.rating-5 {
                        z-index: 2;

                        &:before {
                            @include svg-icon(8.5rem, 1.5rem, $icon-star, $yellow);
                            background-position: left;
                            background-repeat: repeat-x;
                        }

                        &:hover {
                            &::before {
                                @include svg-icon(8.5rem, 1.5rem, $icon-star, $yellow);
                                background-position: left;
                                background-repeat: repeat-x;
                            }
                        }
                    }

                    &.rating-4 {
                        z-index: 3;

                        &:before {
                            @include svg-icon(6.9rem, 1.5rem, $icon-star, $yellow);
                            background-position: left;
                            background-repeat: repeat-x;
                        }
                    }

                    &.rating-3 {
                        z-index: 4;

                        &:before {
                            @include svg-icon(5rem, 1.5rem, $icon-star, $yellow);
                            background-position: left;
                            background-repeat: repeat-x;
                        }
                    }

                    &.rating-2 {
                        z-index: 5;

                        &:before {
                            @include svg-icon(3.35rem, 1.5rem, $icon-star, $yellow);
                            background-position: left;
                            background-repeat: repeat-x;
                        }
                    }

                    &.rating-1 {
                        z-index: 6;

                        &:before {
                            @include svg-icon(1.75rem, 1.5rem, $icon-star, $yellow);
                            background-position: left;
                            background-repeat: repeat-x;
                        }
                    }
                }

                &:before {
                    @include svg-icon(8.5rem, 1.5rem, $icon-star-outline, $secondary);
                    background-position: left;
                    background-repeat: repeat-x;
                }
            }

            .add-review {
                &.btn-cta,
                &:active {
                    background-color: $primary;
                    border-color: $primary;
                    box-shadow: 0 10px 20px 0 $gray-200;
                }

                &:focus {
                    border: 1px solid $white;
                    outline: 5px auto -webkit-focus-ring-color;
                    outline-offset: -2px;
                    box-shadow: none;
                }

                .icon-pen-square {
                    @include svg-icon(1rem, 1rem, $icon-pen-square, $white);
                    vertical-align: middle;
                }
            }
        }
    }
}

.rating-summary {
    display: flex;
    align-items: center;

    .rating-label,
    .label {
        margin-right: ($spacer * 0.5);
        font-weight: $font-weight-bold;
    }

    .rating-result {
        display: flex;
        align-items: center;

        >span {
            position: relative;
            display: block;
            overflow: hidden;
            z-index: 2;


            >span {
                display: none;
            }
        }
    }
}

.review-list {
    .block-title {
        font-size: $h3-font-size;
    }

    .review-items {
        padding: 0;
        list-style: none;

        .review-item {
            .review-title {
                font-size: $h5-font-size;
            }

            .review-ratings {
                margin-bottom: $spacer;
            }

            .review-date {
                color: $gray-500;
            }
        }
    }
}

.block-reviews-dashboard {
    .items {
        padding-left: $spacer;

        .item {
            .product-name {
                margin-right: $spacer;
            }

            .rating-summary {
                display: inline-flex;
                align-items: center;
            }
        }
    }
}

.table-reviews {
    caption {
        display: none;
    }

    .rating-summary {
        .label {
            display: none;
        }
    }
}

.customer-review {
    .reviews-actions {
        margin-bottom: map-get($spacers, 3);

        a {
            +a {
                margin-left: map-get($spacers, 3);
            }
        }
    }

    .rating-summary {
        margin-bottom: map-get($spacers, 3);
    }

    .review-title {
        font-weight: $font-weight-bold;
    }

    .review-date {
        margin-top: map-get($spacers, 2);
        color: $gray-500;
    }
}
