.page-title-wrapper {
    margin-bottom: map-get($spacers, 3);

    .page-title {
        color: $primary;
        @include font-size($h1-font-size);
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-normal;
    }

    .product-reviews-summary {
        .rating-summary {
            .rating-result {
                min-width: 3.45rem;
                &::before {
                    @include svg-icon(100%, 0.7rem, $icon-star-sharp, $gray-100);
                    background-position: left;
                    background-repeat: repeat-x;
                    vertical-align: middle;
                    margin-top: 5px;
                }

                span {
                    &::before {
                        @include svg-icon(100%, 0.7rem, $icon-star-sharp, $yellow);
                        background-position: left;
                        background-repeat: repeat-x;
                        vertical-align: middle;
                        margin-bottom: 3px;
                    }
                }
            }
        }
        .action {
            &.view {
                display: none;
            }

            &.add {
                text-decoration: underline;
            }
        }
    }
}
.fotorama, .fotorama--fullscreen {
    margin-bottom: 2.143em;

    .fotorama__caption {
        display: none;
    }
    .fotorama__thumb {
        height: 90%;
        background: transparent;
    }
    .fotorama__thumb-border {
        border: none;
        background-image: none;
    }
}
.fotorama__fullscreen {
    .fotorama--fullscreen {
        z-index: 99 !important;
    }
}
.product-media  {
    .fotorama__arr{
        background: none !important;

        &:hover, &:focus {
            background: none !important;
        }
    }
    .fotorama__arr--prev .fotorama__arr__arr,
    .fotorama__arr--next .fotorama__arr__arr {
        background: none;
    }

    .fotorama__arr--next {
        &::before {
            right: 0;
        }
    }


    .fotorama__arr--prev {
        &::before {
            left: 0;
        }
    }

    .fotorama__arr--prev, .fotorama__arr--next {
        .fotorama_arr_arr {
            background: none;
        }
        &::before {
            position: absolute;
            padding: map-get($spacers, 3);
            display: block;
            top: 50%;
            transform: translateY(-50%);
            width: 2.5em;
            height: 2.5em;
            padding-left: 1em;
            border-radius: $rounded-pill ;
            line-height: 2.5em;
            color: #fff;
            background: $primary;
            font-size: 1em;
            box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.1);
            z-index: 99 !important;
        }
    }
    .fotorama__arr--prev  {
        &::before {
            @include svg-icon(1.25rem, 1.25rem, $icon-angle-left, $white);
        }
    }
    .fotorama__arr--next {
        &::before {
            @include svg-icon(1.25rem, 1.25rem, $icon-angle-right, $white);
        }
    }
    .fotorama__active .fotorama__dot {
        background-color: $primary;
        border-color: $primary;
    }
    .product-label {
        top: 0;
    }
    .fotorama__nav__frame--thumb {
        opacity: 0.5;

        &.fotorama__active {
            opacity: 1;
        }
    }
}
.fotorama__zoom-in--disabled, .fotorama__zoom-out--disabled  {
    display: block !important;
}
.fotorama__nav-wrap--horizontal{
    .fotorama__img {
        max-height: 85px !important;
        width: auto;
        object-fit: contain;
    }
}
.product-info-main {
    padding: 2rem;
    background: lighten($gray-100, 5);

    .price-box {
        @include font-size($h1-font-size);

        .price-label {
            display: none;
        }

        .price-final_price {
            display: block;
            color: $secondary;
            font-weight: $font-weight-normal;
        }

        .old-price {
            .price-final_price {
                display: block;
                color: $gray-300;
                font-size: $font-size-sm;
                text-decoration: line-through;
            }
        }
    }

    .product-attribute {
        color: $gray-700;

        &.single-product-usp {
            color: $green;
            padding-bottom: $spacer;
        }
    }

    .product-options-wrapper {
        .swatch-attribute-label {
            color: $gray-700;
        }

        .swatch-option {
            min-width: 2.5rem;
            height: 1.75rem;
            border: 0;
            box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.1);

            &.color {
                padding: 1px 2px;

                &:not(.disabled):hover {
                    box-shadow: none;
                    outline: 2px solid $red;
                    border: 1px solid $white;
                }
            }

            &.text {
                font-weight: $font-weight-bold;
                color: $gray-400;
                background-color: $white;

                &:hover {
                    color: $black;
                    border: 1px solid $gray-400;
                }
            }

            &.selected {
                outline: 3px solid $secondary;
                color: $black;
            }
        }
    }
}

.product-attribute {
    .label {
        font-weight: $font-weight-bold;
    }
}

.box-tocart {
    .spinbox {
        margin-right: map-get($spacers, 3);

        label {
            margin-right: map-get($spacers, 3);
        }

        .spinbox-input {
            max-width: $spinbox-width;
            text-align: center;
        }
    }
}

.product-addto-links {
    margin-top: map-get($spacers, 3);
}

.description,
.attributes-collapse {
    margin-bottom: map-get($spacers, 4);

    .table-bordered {
        width: 100% !important;
    }
}

.attributes-collapse {
    .label {
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        line-height: $headings-line-height;
        color: $headings-color;
        @include font-size($h3-font-size);
    }

    .label-collapse {
        &:after {
            @include svg-icon(1.25rem, 1.25rem, $icon-minus-square-solid, $pink);
            margin-left: map-get($spacers, 4);
            vertical-align: middle;
        }

        &.collapsed {
            &:after {
                @include svg-icon(1.25rem, 1.25rem, $icon-plus-square-solid, $pink);
            }
        }
    }
}

.additional-attributes {
    dl {
        @include make-col-ready();

        .item {
            @include make-row();
            padding: map-get($spacers, 2) 0;

            &+.item {
                border-top: 1px solid lighten($gray-100, 2.25);
            }
        }

        dt {
            @include make-col(5);

            &::first-letter {
                text-transform: capitalize;
            }
        }

        dd {
            margin: 0;
            @include make-col(7);
        }
    }
}

.product-details-wrapper {
    padding: map-get($spacers, 5) 0;
    margin-bottom: map-get($spacers, 4);

    .title, h2 {
        color: $secondary;
        font-weight: $font-weight-base;
    }

    .title {
        font-family: $headings-font-family;
        @include font-size($h1-font-size);

        @include media-breakpoint-down($main-breakpoint) {
            @include font-size($h2-font-size);
        }
    }

    h2 {
        @include font-size($h2-font-size);

        @include media-breakpoint-down($main-breakpoint) {
            @include font-size($h3-font-size);
        }
    }
}

.product-size {
    margin-bottom: $spacer;
    .icon-th-large {
        @include svg-icon(0.875rem, 0.875rem, $icon-th-large, $secondary);
        vertical-align: middle;
    }
}
.size-guide {
    padding: 2rem;
    max-width: 40rem;
    width: 100%;

    .btn-close {
        float: right;
        .icon-times {
            @include svg-icon(0.75rem, 0.75rem, $icon-close, $gray-800);
        }
    }

    .table.table-bordered {
        border: 1px solid $gray-200;
        tr {
            td {
                padding: 0.5rem;
                border: 1px solid $gray-200;
                color: $gray-700;
                font-weight: $font-weight-base;
            }
        }
    }
}


.product-pros-cons-wrapper {
    padding-top: map-get($spacers, 5);
    background: lighten($gray-100, 2.5);

    .product_pros_cons_title {
        @include font-size($h2-font-size);
        line-height: 2.25;
        font-family: $headings-font-family;
        color: $pink;
    }

    .product-pros, .product-cons {
        .value {
            ul {
                padding-left: 0;
                list-style: none;

                li {
                    padding-bottom: 0.3rem;
                    border-bottom: 1px dashed $gray-200;

                    &+li {
                        margin-top: 0.3rem;
                    }

                    &:last-of-type {
                        border-bottom: 0;
                    }

                    &::before {
                        margin-right: map-get($spacers, 2);
                        vertical-align: middle;
                    }
                }
            }
        }
    }

    .product-pros .value ul li {
        &::before {
            @include svg-icon(1rem, 1rem, $icon-plus-circle, $green);
        }
    }


    .product-cons .value ul li {
        &::before {
            @include svg-icon(1rem, 1rem, $icon-minus-circle, $red);
        }
    }

    .product-pros-cons-image {
        margin-top: -6.286em;
        float: right;

        @include media-breakpoint-down($main-breakpoint) {
            margin-top: 0;
        }
        @include media-breakpoint-down(sm) {
            float: none;
            text-align: center;
        }
	}
}

.product-options-bottom {
    margin-right: -4rem;
    padding: map-get($spacers, 3);
    background-color: $white;
    border: 0.125rem dotted $primary;
    border-radius: $border-radius;

    @include media-breakpoint-down($main-breakpoint) {
        margin-right: 0;
    }
    .box-tocart {
        .form-inline {
            justify-content: space-between;
            flex-flow: inherit;

            @include media-breakpoint-down($main-breakpoint) {
               justify-content: center;
               flex-direction: column;
               align-items: stretch;
            }

            .field {
                @include media-breakpoint-down($main-breakpoint) {
                    margin-bottom: map-get($spacers, 3);
                    width: 100%;
                    flex-direction: column;
                    align-items: stretch;

                    .spinbox{
                        margin-right: 0;

                       .input-group {
                          justify-content: space-between;
                       }
                    }
                 }
            }

            .actions {
                @include media-breakpoint-down($main-breakpoint) {
                    margin-bottom: 0;
                }

                .tocart {
                    margin-right: map-get($spacers, 3);
                    width: 17.25rem;
                    height: 3.4rem;
                    font-size: 1rem;
                    font-weight: $font-weight-bold;
                    text-transform: uppercase;

                    @include media-breakpoint-down($main-breakpoint) {
                        margin-right: 0;
                        width: 100%;
                        height: auto;
                        padding: map-get($spacers, 2) map-get($spacers, 3);
                     }
                    @include media-breakpoint-only(lg) {
                        width: 12.25rem;
                    }

                    &.btn-cta, &:active {
                        background-color: $primary;
                        border-color: $primary;
                    }

                    &:focus {
                        border: 1px solid $white;
                        outline: 5px auto -webkit-focus-ring-color;
                        outline-offset: -2px;
                        box-shadow: none;
                    }

                    &:focus, &:hover {
                        background-color: lighten($primary, 10);
                        border-color: lighten($primary, 10);
                    }
                    .icon-cart {
                        @include svg-icon(1.25rem, 1.25rem, $icon-shopping-cart, $white);
                        vertical-align: sub;
                    }
                }
            }
        }
    }
}

$iconClasses: (
    truck: $icon-truck, 
    money-bill-alt: $icon-money-bill-alt,
    star: $icon-star-sharp, 
    thumbs-up: $icon-thumbs-up, 
    phone: $icon-phone
);    
$iconSize: 1rem;

@each $iconClass, $iconValue in $iconClasses {
    .product-usp {
        .icon {
            vertical-align: middle;
            &-#{$iconClass} {
                @include svg-icon($iconSize, $iconSize, $iconValue, $pink);
            }
        }
    }
}

.product-usp {
    margin-top: map-get($spacers, 4);

    &+.product-usp {
        margin-top: 0;
    }
    .block-title {
        margin-bottom: map-get($spacers, 2);
        @include font-size($h4-font-size);
        font-weight: $headings-font-weight;
        color: $gray-700;
    }

    .product-usp-item {
        color: $secondary;
        margin-bottom: map-get($spacers, 2);
    }
}

.product-review-wrapper {
    padding: map-get($spacers, 5) 0;
    .review-list {
        .title {
            margin-bottom: map-get($spacers, 4);
            color: $pink;
            font-size: $h1-font-size;
            font-weight: $font-weight-base;
            font-family: $headings-font-family;
        }

        .review-item {
            padding-bottom:  map-get($spacers, 3);
            margin-bottom:  map-get($spacers, 3);
            border-bottom: 1px dashed lighten($gray-100, 2.25);

            &:last-of-type {
                border-bottom: 0;
            }

            &>div {
                @include media-breakpoint-down(sm) {
                    row-gap: map-get($spacers, 3);
                }
            }

            .review-ratings {
                margin-bottom: map-get($spacers, 2);

                .rating-summary {
                    @include media-breakpoint-down(sm) {
                        width: 35%;
                    }
                    .rating-label {
                        font-weight: $font-weight-normal;
                    }
                    .rating-result {
                        min-width: 3.8rem;
                        margin-left: auto;


                        &>span {
                            @include svg-mask($icon-star-sharp);
                            width: 100% !important;
                            height: 1.25rem;
                            -webkit-mask-size: 20%;
                            mask-size: 20%;
                            -webkit-mask-repeat-x: inherit;
                            mask-repeat-x: inherit;
                            -webkit-mask-position-x: left;
                            -webkit-mask-position-y: center;
                            mask-position-x: left;
                            mask-position-y: center;
                        }
                    }
                }
            }

            .review-details {
                .review-author {
                    font-size: $font-size-sm;
                    color: $gray-300;
                    font-weight: $font-weight-normal;
                }
            }

            .review-title {
                color: $secondary;
                font-weight: $font-weight-bold;
            }
        }
    }

    .add-review{
        .add-review {
            &.btn-cta,
            &:active {
                background-color: $primary;
                border-color: $primary;
                border-radius: $border-radius-lg;
                box-shadow: 0 10px 20px 0 $gray-200;
                font-size: $font-size-base;
                font-weight: $font-weight-bold;

            }

            &:focus{
                border: 1px solid $white;
                outline: 5px auto -webkit-focus-ring-color;
                outline-offset: -2px;
                box-shadow: none;
            }

            &:not(:disabled):not(.disabled):active:focus {
                box-shadow: 0 10px 20px 0 $gray-200;
            }

            .icon-pen-square {
                @include svg-icon(1rem, 1rem, $icon-pen-square, $white);
                vertical-align: middle;
            }
        }
    }
}

.spinbox {
    width: fit-content;

    @include media-breakpoint-down($main-breakpoint) {
        width: 100%;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }
    .input-group {
        border: 2px solid $secondary;
        border-radius: $border-radius;

        @include media-breakpoint-down($main-breakpoint) {
            justify-content: space-between;
        }

        .spinbox-input {
            border: 0;
            padding: 0 map-get($spacers, 2);

            &:focus {
                box-shadow: none;
            }
        }

        .input-group-btn {
            display: flex;
            align-items: center;

            button.btn-qty {
                padding: 0 map-get($spacers, 1);

                &:focus {
                    box-shadow: none;
                }

                .icon-minus {
                    @include svg-icon(0.75rem, 0.75rem, $icon-minus, $primary);
                }

                .icon-plus {
                    @include svg-icon(0.75rem, 0.75rem, $icon-plus, $primary);
                }
            }
        }
    }
}

.product-related-wrapper {
    .block-title {
        margin-bottom: $spacer;
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        @include font-size($h1-font-size);
        color: $pink;
    }

    .products-grid {
        .product-item-info {
            padding: 0 map-get($spacers, 3);
        }
        .product-item-details {
            padding-bottom: 0;
            min-height: auto;

            .product-item-actions {
                display: none;
            }
        }
    }
}

.crosssell-product, .cart-crosssell-wrapper {
    display: flex;
    column-gap: map-get($spacers, 4);

    .block-title {
        strong {
            padding: 0 9rem;
            text-align: end;

            .title, .subtitle {
                display: block;
            }
            .title {
                color: $primary;
                line-height: 1;
            }
            .subtitle {
                position: relative;
                font-family: $font-family-sans-serif;
                font-size: $font-size-base;
                color: $pink;

                &::after {
                    position: absolute;
                    top: 1rem;
                    right: -1rem;
                    @include svg-icon(40px, 50px, $icon-arrow-right-journal, $pink);
                    transform: rotateZ(-10deg);
                }
            }
        }
    }
    .products-crosssell-product, .crosssell{
        padding: map-get($spacers, 3);
        border: 0.25rem dashed $primary;
        border-radius: $border-radius-lg;

        .product-items {
            .product-item {
                .product-item {
                    &-info {
                        max-width: 12rem;
                        margin: 0 auto;
                    }
                    &-details {
                        .price-box {
                            flex-direction: row-reverse;
                        }
                    }
                    &-actions {
                        .actions-primary {
                            .btn-cta {
                                background-color: $primary;
                                border-color: $primary;

                                &:hover {
                                    background-color: lighten($primary, 5);
                                    border-color: lighten($primary, 5);
                                }

                                &:focus {
                                    box-shadow: none;
                                }
                                span {
                                    display: flex;
                                    align-items: center;

                                    .icon {
                                        &-plus {
                                            @include svg-icon(0.95rem, 0.95rem, $icon-plus, $white);
                                            margin-right: map-get($spacers, 2);
                                        }
                                        &-shopping-cart {
                                            @include svg-icon(0.95rem, 0.95rem, $icon-shopping-cart, $white);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
