.message, .alert {
    @extend .alert;

    &.info {
        @extend .alert-info;
    }
    &.danger {
        @extend .alert-danger;
    }
    &.warning {
        @extend .alert-warning;
    }
    &.notice {
        @extend .alert-primary;
    }
    &.success {
        @extend .alert-success;
    }
}
