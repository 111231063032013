header.page-header .top-wrapper {
    background: $primary;

    a {
        color: $white;
    }

    .feedback-company {
        text-align: right;

        .stars {
            display: inline-block;
        }

        .rating-summary .rating-result:before {
            color: transparent;
        }

        .rating-summary .rating-result {
            position: absolute;
            float: right;
            right: 13.5em;
            bottom: 0;
        }

        .scores {
            display: inline-block;
            text-decoration: underline;
        }
    }

    .top-usp {
        display: flex;
        align-items: center;

        .usp-block {
            a {
                display: flex;
                align-items: center;
                column-gap: map-get($spacers, 1);
                .icon {
                    margin-right: map-get($spacers, 1);
                    
                    &.icon-truck {
                        @include svg-icon(1.2rem, 1.2rem, $icon-truck, $secondary);
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}
.icon-truck {
    @include svg-icon(1rem, 1rem, $icon-truck, $secondary);

}
