.cookie-status-message {
    display: none;
}

header {
    margin-bottom: map-get($spacers, 3);
    position: inherit;
    z-index: 1045; 
    .top-wrapper {
        margin-bottom: 0;
        @include media-breakpoint-down($main-breakpoint) {
            display: block;
            margin-bottom: 0!important;
        }
        .top-usp {
            padding: 0.45rem 0;
            @include media-breakpoint-down($main-breakpoint) {
                justify-content: center;
            }
        }
    }

    .collapsed.btn-header-block{
        @include media-breakpoint-down($main-breakpoint) {
            display: none;
        }
    }
    input#search_search_mini_form {
        height: 34px;
    }

    .top-header {
        flex-wrap: nowrap;
        justify-content: center;
    }

    .header.links {
        display: none;
    }

    @include media-breakpoint-down($main-breakpoint) {
        margin-bottom: 0;

        .navbar .navbar-nav {
            padding: 0;
            font-size: 1.4rem;

            li {
                padding: 0.8rem 1rem;
            }
            .menu-bar {
                background: $secondary;
                color: $white;
            }
        }
    }

    .top-wrapper {
        padding: 0;
        margin-bottom: 0;

        ul {
            li {
                +li {
                    margin-left: map-get($spacers, 2);
                }
            }
        }
    }

    .header {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        background-color: $white;

        .top-wrapper {
            margin-bottom: 0;

            @include media-breakpoint-down($main-breakpoint) {
                display: block;
                margin-bottom: 0;
            }
        }
    }
}

.logo-slogan {
    position: relative;

    .logo-relative {
        position: absolute;
        z-index: 1;
        top: -2.85rem;


        a {
            img {
                height: auto;
                aspect-ratio: auto 300 / 101;
            }
        }
    }
}
.btn-header-block {
    max-width: 13rem;
    margin-left: 3rem;
    background-color: $white;
    padding: map-get($spacers, 1) 0;
    @include border-radiuses(0.625rem, 0.625rem, 0, 0);
    text-align: center;
    cursor: pointer;

    .btn-total {
        display: flex;
        margin-top: map-get($spacers, 1);
        align-items: center;

        .btn-text {
            margin-left: map-get($spacers, 2);
            display: inline-block;
            position: relative;
            color: $secondary;
            white-space: nowrap;
    
        }

        .icon {
            @include svg-icon(0.85rem, 0.85rem,  $icon-close, $pink);
            margin: 0 map-get($spacers, 2);
            
        }
    }


    &.collapsed{
        .btn-total {
            .icon {
                @include svg-icon(0.85rem, 0.85rem,  $icon-bars, $red);
            }
        }
    }

}
.breadcrumbs-wrapper {
    margin-top: 0;
    background-color: $breadcrumb-bg;
    min-height: auto;

    .breadcrumb {
        font-size: 0.82rem;

        .active {
            span {
                color: $breadcrumb-active-color;
                font-weight: $font-weight-bold;
            }
        }

        &>li+li {
            padding-left: 0;

            &:before {
                padding: 0 $breadcrumb-item-padding;
                content: '>';
                color: $breadcrumb-divider-color;
                transform: rotateY(30deg);
            }
        }
    }
}
.slogan-wrapper {
    display: flex;
    align-items: center;
    margin-left: 36%;
    .slogan {
        font-family: $headings-font-family;
        color: $secondary;
        @include font-size($h2-font-size);
        user-select: none;
        letter-spacing: 1.07px;
    }
}

.btn.header-minicart {
    padding: 0;
    margin-right: -2rem;
    margin-left: 1rem;
}

.header-minicart,
.navbar-cart {
    .icon {
        &::before {
            @include svg-icon(3rem, 2.5rem,  $icon-cart, $pink);
        }
    }

    .counter {
        position: absolute;
        display: block;
        width: 27px;
        height: 27px;
        line-height: 1.8;
        background: $secondary;
        color: $white;
        border: 2px solid $white;
        text-align: center;
        font-size: $font-size-sm;
        border-radius: $rounded-pill;
    }

    .counter-label {
        display: none;
    }
}

.header-minicart .counter {
    left: 0.6rem;
    top: -0.2rem;
}

.navbar-cart {
    position: relative;

    .icon {
        &::before {
            transform: scale(0.65);
        }
    }

    .counter {
        transform: scale(0.65);
        left: 0.5rem;
        top: 0;
        z-index: 1;
        &.empty {
            display: none;
        }
    }
}

.header-sub {
    max-height: 75px;
}
.navbar {
    background-image: url(../images/pattern-waves.png);
    padding: 0.72rem 1rem;
}
#header-block {
    color: $white;

    .header-block {
        padding: 1.25rem 0 0.65rem;

        p {
            margin-bottom: 0.625rem;
        }
    }
}

