.pagination {
    margin: 0;
    align-items: center;
    border: $border-width solid $gray-200;
    font-size: $font-size-sm;
    
    .item {
        padding: 5px 0.625rem;
        cursor: pointer;
        
        &:not(.current):hover {
            background-color: $gray-100;
        }
        .page {
            &:hover {
                text-decoration: none;
            }
        }

        &.active {
            span {
                font-weight: $font-weight-bold;
                color: $secondary;
            }
        }

        &.pages-item-previous, 
        &.pages-item-next {
            span {
                color: $primary;
            }
        }

        &.current {
            background-color: $primary;
            span {
                color: $white;
            }

        }
    }
}