@font-face {
    font-family: 'allstar';
    src: url('../fonts/configurator/all-star-resort-webfont.eot');
    src: url('../fonts/configurator/all-star-resort-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/configurator/all-star-resort-webfont.woff') format('woff'),
        url('../fonts/configurator/all-star-resort-webfont.ttf') format('truetype'),
        url('../fonts/configurator/all-star-resort-webfont.svg#allstarregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'edwardian-script';
    src: url('../fonts/configurator/EdwardianScriptITC.eot');
    src: url('../fonts/configurator/EdwardianScriptITC.eot?#iefix') format('embedded-opentype'),
        url('../fonts/configurator/EdwardianScriptITC.woff') format('woff'),
        url('../fonts/configurator/EdwardianScriptITC.ttf') format('truetype'),
        url('../fonts/configurator/EdwardianScriptITC.svg#allstarregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'handwriting';
    src: url('../fonts/configurator/lucida_handwriting-webfont.eot');
    src: url('../fonts/configurator/lucida_handwriting-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/configurator/lucida_handwriting-webfont.woff') format('woff'),
        url('../fonts/configurator/lucida_handwriting-webfont.ttf') format('truetype'),
        url('../fonts/configurator/lucida_handwriting-webfont.svg#allstarregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'sweetheart-birthday';
    src: url('../fonts/configurator/sweetheart_birthday-webfont.eot');
    src: url('../fonts/configurator/sweetheart_birthday-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/configurator/sweetheart_birthday-webfont.woff') format('woff'),
        url('../fonts/configurator/sweetheart_birthday-webfont.ttf') format('truetype'),
        url('../fonts/configurator/sweetheart_birthday-webfont.svg#allstarregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'trumpit';
    src: url('../fonts/configurator/trumpit-webfont.eot');
    src: url('../fonts/configurator/trumpit-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/configurator/trumpit-webfont.woff') format('woff'),
        url('../fonts/configurator/trumpit-webfont.ttf') format('truetype'),
        url('../fonts/configurator/trumpit-webfont.svg#allstarregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'lucida_calligraphy';
    src: url('../fonts/configurator/lucida-calligraphy-webfont.eot');
    src: url('../fonts/configurator/lucida-calligraphy-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/configurator/lucida-calligraphy-webfont.woff') format('woff'),
        url('../fonts/configurator/lucida-calligraphy-webfont.ttf') format('truetype'),
        url('../fonts/configurator/lucida-calligraphy-webfont.svg#allstarregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

.product-configurator {
    margin-right: -4rem;
    margin-bottom: map-get($spacers, 3);
    padding: map-get($spacers, 3);
    background-color: lighten($light-blue, 22.5);
    border: 0.125rem dotted $primary;
    border-radius: $border-radius;

    @include media-breakpoint-down($main-breakpoint) {
        margin-right: 0;
    }

    .checkbox {
        .configurate {
            vertical-align: middle;

        }

        label {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            column-gap: map-get($spacers, 1);
        } 

        span {
            color: $secondary;
            font-weight: $font-weight-bold;
            white-space: nowrap;

            @include media-breakpoint-down(lg) {
                white-space: normal;
            }

            &::after {
                @include svg-icon(1rem, 1rem, $icon-star, $pink);
                margin-left: map-get($spacers, 2);
                vertical-align: sub;
            }
        }

        small {
            color: $gray-700 !important;
        }
    }
}

.configurator {
    display: none;
    margin-right: -4rem;
    margin-bottom: map-get($spacers, 3);
    padding: map-get($spacers, 3);
    background-color: $white;
    border: 0.125rem dotted $primary;
    border-radius: $border-radius;

    @include media-breakpoint-down(sm) {
        margin-right: 0;
    }

    &.active {
        display: block;
    }

    h3,
    h4 {
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-normal;
        color: $gray-700;
    }

    h4 {
        font-size: 1.125rem;
    }

    .title {
        margin-bottom: map-get($spacers, 4);
    }

    .panel {
        padding-bottom: map-get($spacers, 3);
        margin-bottom: map-get($spacers, 3);
        border-bottom: 1px solid $gray-100;

        .configurator-step-title {
            &::before {
                @include svg-icon(1rem, 1rem, $icon-angle-down, $gray-700);
                vertical-align: middle;
                float: right;
            }

            &.collapsed {
                &::before {
                    @include svg-icon(1rem, 1rem, $icon-angle-right, $gray-700);
                }
            }

            &.done {
                &::after {
                    @include svg-icon(0.75rem, 0.75rem, $icon-check-circle, $green);
                    margin-left: map-get($spacers, 2);
                    vertical-align: middle;
                }
            }
        }

        &.configurator__text{
            .help-block, .help-block-warning, .sample-text>label {
                color: lighten($gray-500, 2);
            }

            .help-block {
                margin-top: map-get($spacers, 2);
                margin-bottom: 0;
            }

            .sample-text {
                label {
                    font-family: $font-family-base;
                }

                .text-position {
                    display: block;
                    width: 100%;
                    padding: 0.429em;
                    text-align: center;
                    font-size: 18px;
                    border: 1px solid lighten($gray-100, 5);
                    border-radius: 4px;
                    -moz-border-radius: 4px;
                    -webkit-border-radius: 4px;
                    background: #f5f5f5;
                    overflow: hidden;
                    min-height: 2.5rem;

                }
            }
        }

        .radio,
        .selection {
            .color-wrapper {
                display: inline-block;
                width: 1.75rem;
                min-width: 1.75rem;
                height: 0.875rem;
                border-radius: $border-radius;
            }
        }

        .configurator-totals {
            background-color: lighten($gray-100, 5);
            border-radius: $border-radius;

            &.table {
                .totals {

                    .name,
                    .amount {
                        border: 0;
                        padding: map-get($spacers, 2) map-get($spacers, 3);
                    }

                    &.final-price {
                        font-size: $font-size-lg;
                        font-weight: $font-weight-bold;
                    }
                }
            }
        }
    }
}

$themeFonts: ("allstar": 'allstar',
    "arial": 'arial',
    "handwriting": 'handwriting',
    "calligraphy": 'lucida_calligraphy',
    "times-new-roman": "TimesNewRoman",
    "edwardian-script": 'edwardian-script',
    "sweety-rasty": 'sweety-rasty',
    "trumpit": 'trumpit',
    "sweetheart-birthday": 'sweetheart-birthday'
);

$themeColours: (
    "antraciet": #293133,
    "aqua-blauw": #28b8e6,
    "blauwe-sterrenstof": #3da6c8,
    "creme": #fffcd2,
    "glow-in-the-dark": #d7fa9b,
    "goudkleurig": #f6c935,
    "lichtblauw":#aed8e5,
    "lime-groen": #a1fb4b,
    "marine-blauw": #081471,
    "oranje": #fd7f24,
    "paars": #5b2497,
    "rood": #fc101c,
    "roze": #fd6cb4,
    "roze-sterrenstof": #e44388,
    "fuchsia-roze": #c54575,
    "wit": $white,
    "zilver": lighten($gray-100, 4.75),
    "zwart": $black,
    "neon-groen": #39FF14,
    "neon-geel": #FFFF00,
    "neon-oranje": #FF8300,
    "neon-roze": #FF1493,
);

@each $colorName,
$colorCode in $themeColours {
    .configurator {
        .panel {

            .radio,
            .selection {
                &.#{$colorName} {
                    .color-wrapper {
                        background-color: $colorCode;
                        border: ($border-width / 4) solid darken($colorCode, 10);
                    }
                }
            }

            &.configurator__text {
                .sample-text {
                    &.#{$colorName} {
                        color: $colorCode;
                    }
                }
            }
        }
    }
}

@each $className,
$fontName in $themeFonts {
    .#{$className} {
        font-family: $fontName;
        font-size: $font-size-lg;
    }
}