.header-minicart .text {
    display: none;
}
.header-minicart .icon {
    content: '';
    display: inline-block;
    width: 3.5rem;
    height: 2rem;
    background-image: none;
    background-repeat: no-repeat;
    background-position: center;
}
.counter {
    &.empty {
        display: block;
    }
}
.navbar-minicart .icon {
    background-image: unset;
}
