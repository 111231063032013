/*------------------------------------------------------------*\
    MODALS
\*------------------------------------------------------------*/

body {
	&._has-modal {
		height: 100%;
		overflow: hidden;
		width: 100%;
	}

	&._has-modal-custom {
		.modal-custom-overlay {
			height: 100vh;
			left: 0;
			position: fixed;
			top: 0;
			width: 100vw;
			z-index: 899;
		}
	}
}
.modals-overlay {
	z-index: 899;
}
.modal-custom, .modal-popup, .modal-slide {
	.action-close {
		background-image: none;
		background: none;
		-moz-box-sizing: content-box;
		border: 0;
		box-shadow: none;
		line-height: inherit;
		margin: 0;
		padding: 0;
		text-shadow: none;
		font-weight: 400;
		display: inline-block;
		text-decoration: none;
		position: absolute;
		right: 0;
		top: 0;

		> span {
			border: 0;
			clip: rect(0, 0, 0, 0);
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			width: 1px;
		}
		&:hover, &:focus, &:hover {
			background: none;
			border: none;
		}
		&.disabled, &[disabled] {
			cursor: not-allowed;
			pointer-events: none;
			opacity: 0.5;
		}
		&:before {
            @include svg-icon(1rem, 1rem, $icon-close, $gray-500);
			display: inline-block;
			margin: 0;
			line-height: 28px;
			vertical-align: top;
			overflow: hidden;
			text-align: center;
		}
	}
}
.modal-slide,
.modal-popup {
	bottom: 0;
	left: 0;
	min-width: 0;
	position: fixed;
	right: 0;
	top: 0;
	visibility: hidden;

	&._show {
		visibility: visible;

		.modal-inner-wrap {
			-webkit-transform: translate(0, 0);
			transform: translate(0, 0);
		}
	}

	.modal-inner-wrap {
		background-color: #ffffff;
		box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);
		opacity: 1;
		pointer-events: auto;
		
		.primary {
			display: inline-block;
		}
		.secondary {
			display: inline-block;
		}
	}
}
.modal-slide {
	left: 44px;
	z-index: 900;

	&._show {
		.modal-inner-wrap {
			-webkit-transform: translateX(0);
			transform: translateX(0);
		}
	}
	.modal-inner-wrap {
		height: 100%;
		overflow-y: auto;
		position: static;
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
		transition-duration: .3s;
		-webkit-transition-property: -webkit-transform, visibility;
		transition-property: transform, visibility;
		transition-timing-function: ease-in-out;
		width: auto;
	}
	&._inner-scroll {
		.modal-inner-wrap {
			overflow-y: visible;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-flex-direction: column;
			-ms-flex-direction: column;
			flex-direction: column;
		}
		.modal-header, .modal-footer {
			-webkit-flex-grow: 0;
			flex-grow: 0;
			-webkit-flex-shrink: 0;
			flex-shrink: 0;
		}
		.modal-content {
			overflow-y: auto;
		}
		.modal-footer {
			margin-top: auto;
		}
	}

	.modal-header, .modal-content, .modal-footer {
		padding: 0 2.6rem 2.6rem;
	}
	.modal-header {
		padding-bottom: 2.1rem;
		padding-top: 2.1rem;
	}
	.action-close {
		padding: 2.1rem 3rem;
	}
	.page-main-actions {
		margin-bottom: -12.9rem;
		margin-top: 2.1rem;
	}
}
.modal-popup {
	z-index: 1046 !important;
	left: 0;
	overflow-y: auto;

	&._show {
		.modal-inner-wrap {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
	}
	.modal-inner-wrap {
		margin: 3rem auto;
		width: $modal-lg;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		box-sizing: border-box;
		height: auto;
		left: 0;
		position: absolute;
		right: 0;
		-webkit-transform: translateY(-200%);
		transform: translateY(-200%);
		transition-duration: .2s;
		-webkit-transition-property: -webkit-transform, visibility;
		transition-property: transform, visibility;
		transition-timing-function: ease;

		@include media-breakpoint-down($main-breakpoint) {
			width: 600px;
        }
        @include media-breakpoint-down($main-breakpoint) {
			width: 75%;
			margin: 3rem auto;
		}
	}
	&._inner-scroll {
		overflow-y: visible;

		.modal-inner-wrap {
			max-height: 85%;
		}
		.modal-content {
			overflow-y: auto;
		}
		.modal-body {
			overflow-y: auto;
		}

	}
	.modal-header, .modal-content, .modal-footer {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		border: 0;
	}
	.modal-header, .modal-footer {
		-webkit-flex-grow: 0;
		flex-grow: 0;
		-webkit-flex-shrink: 0;
		flex-shrink: 0;
	}
	.modal-header {
		padding-bottom: 1.2rem;
		padding-top: 1rem;
	}
	.modal-footer {
		margin-top: auto;
		padding-bottom: 1em;
		padding-top: 1em;
		margin-left: 0px;
		margin-right: 0px;
	}
	.modal-footer-actions {
		text-align: right;
	}
	.modal-title {
		padding-top: 0.5em;
		padding-bottom: 1em;
		font-size: $h6-font-size;
		color: $success;
		border-bottom: 2px solid $gray-300;

		@include media-breakpoint-down($main-breakpoint) {
			font-size: $h6-font-size;
		}
	}
	.action-close {
		padding: 10px 15px;
	}
	&.popup-authentication {
		.modal-header {
			padding-top: 1.1em;
		}
		.block-customer-login {
			margin-bottom: 0em;
		}
	}
}
.ie10, .ie9 {
	.modal-popup {
		&._inner-scroll {
			overflow-y: auto;

			.modal-inner-wrap {
				max-height: none;
			}
		}
	}
}
fieldset[disabled] {
	.modal-custom, .modal-popup, .modal-slide {
		.action-close {
			cursor: not-allowed;
			pointer-events: none;
			opacity: 0.5;
		}
	}
}
.modal-custom {
	.action-close {
		margin: 25px;
	}
}
.modals-overlay {
	background-color: $modal-backdrop-bg;
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1045 !important;
	opacity: $modal-backdrop-opacity;
}
@include media-breakpoint-up($main-breakpoint) {
	.modal {
		.modal-content {
			padding: 0.5em 1.5em;

			.modal-header {
				padding-left: 0;
				padding-right: 0;
			}
			.modal-footer {
				padding-left: 0;
				padding-right: 0;
				text-align: left;
			}
		}
	}
}
