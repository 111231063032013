.no-display {
    display: none;
}
.sidebar {
    .subtitle {
        display: none;
    }
} 
.block {
    margin-bottom: $spacer;
}
.block-compare , .block-reorder, .widget, .block-wishlist {
    .block-title {
        margin-bottom: $headings-margin-bottom;
        color: $headings-color;
        font-family: $headings-font-family;
        font-size: $font-size-lg;
        font-weight: $headings-font-weight;
        line-height: $headings-line-height;
    }
}
