footer.footer {
    background: $gray-100;
}
.footer-block {
    .block-title {
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        line-height: $headings-line-height;
        color: $headings-color;

        &.label-collapse {
            cursor: pointer;
        }
    }
    .block-content {
        ul {
            padding: 0;
            list-style-type: none;
        }
    }
}
